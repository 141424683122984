import Guid from 'devextreme/core/guid';

export function buildApiUrl(path, parameters) {
    const accountBookId = getAccountBookId() || 0;
    let url = `/api/${accountBookId}/${path}`;

    if (parameters) {
        let queryString = '';

        for (const prop in parameters) {
            const propValue = parameters[prop];

            if (propValue !== null && propValue !== undefined) {
                if (Array.isArray(propValue)) {
                    for (let i = 0; i < propValue.length; i++) {
                        queryString += `${queryString ? '&' : '?'}${prop}=${encodeURIComponent(propValue[i])}`;
                    }
                } else {
                    queryString += `${queryString ? '&' : '?'}${prop}=${encodeURIComponent(propValue)}`;
                }
            }
        }

        url += queryString;
    }

    return url;
}

export const getAccountBookId = () => getSessionData('accountBookId');
export const setAccountBookId = (id) => setSessionData('accountBookId', id);
export const getQRCode = () => getSessionData('qrCode');
export const setQRCode = (qrCode) => setSessionData('qrCode', qrCode);
export const getTableNo = () => getSessionData('tableNo');
export const setTableNo = (tableNo) => setSessionData('tableNo', tableNo);
export const getIsQRCodeStatic = () => getSessionData('isQRCodeStatic');
export const setIsQRCodeStatic = (isQRCodeStatic) => setSessionData('isQRCodeStatic', isQRCodeStatic);
export const getTableSessionId = () => getSessionData('tableSessionId');
export const setTableSessionId = (id) => setSessionData('tableSessionId', id);
export const getShareCart = () => getSessionData('shareCart');
export const setShareCart = (shareCart) => setSessionData('shareCart', shareCart);
export const getUseEPayment = () => getSessionData('useEPayment');
export const setUseEPayment = (useEPayment) => setSessionData('useEPayment', useEPayment);
export const getServiceType = () => getSessionData('serviceType');
export const setServiceType = (serviceType) => setSessionData('serviceType', serviceType);
export const getIsPreview = () => getSessionData('isPreview');
export const setIsPreview = (isPreview) => setSessionData('isPreview', isPreview);
export const getIsLandingPageShown = () => getSessionData('isLandingPageShown');
export const setIsLandingPageShown = () => setSessionData('isLandingPageShown', true);

export const getUserId = () => {
    const key = 'userId';

    let userId = localStorage.getItem(key);
    if (!userId) {
        userId = new Guid().toString();
        localStorage.setItem(key, userId);
    }

    return userId;
};

export const removeSessionData = () => sessionStorage.removeItem('sessionData');

const getSessionData = (key) => {
    const userDataObj = JSON.parse(sessionStorage.getItem('sessionData'));

    if (userDataObj !== null) {
        return userDataObj[key] ?? null;
    }

    return null;
}

const setSessionData = (key, value) => {
    let obj = JSON.parse(sessionStorage.getItem('sessionData')) ?? {};
    obj[key] = value;

    sessionStorage.setItem('sessionData', JSON.stringify(obj));
}