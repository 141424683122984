export function cancelEventPropagation(e) {
    preventDefault(e);
    stopPropagation(e);
}

export function preventDefault(e) {
    // Prevent default behavior
    if (e && e.preventDefault) {
        e.preventDefault();
    }
}

export function stopPropagation(e) {
    // Prevent event propagation
    if (e && e.stopPropagation) {
        e.stopPropagation();
    }
}