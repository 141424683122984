import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import BackButton from 'components/BackButton';
import BottomSheetDialog from 'components/BottomSheetDialog';
import SwipeableHintIcon from 'components/SwipeableHintIcon';
import * as DocumentIdConstants from 'data/documentIdConstant';
import withFormData from 'hocs/withFormData';
import withOrderService from 'hocs/withOrderService';
import MenuItemPrice from 'Menu/components/MenuItemPrice';
import * as ComponentUtils from 'utils/component';
import Footer from './Shared/Footer';
import ModifierCategoryPanel from './Shared/ModifierCategoryPanel';

const SetMealItemForm = ({
    data,
    activeEventKeys,
    accordionButtonRefs,
    setMealItem,
    maxQty,
    isAdd,
    onAccordionSelect,
    toggleAccordion,
    openImageDialog,
    handleChange,
    handleCancel,
    handleSubmit,
    cart }) => {
    function handleMinus() {
        if (data.quantity === 1) return;

        data.quantity -= 1;
        cart.recalculateCartSetMealItemDerivedValues(data);
        handleChange(data);
    }

    function handleAdd() {
        if (data.quantity === maxQty) return;

        data.quantity += 1;
        cart.recalculateCartSetMealItemDerivedValues(data);
        handleChange(data);
    }

    function handleModifierItemChange(values) {
        data.modifierItems = values.modifierItems;
        cart.sortModifierItems(data.modifierItems);
        cart.recalculateCartSetMealItemDerivedValues(data);
        handleChange(data);
    }

    function renderDetailContent() {
        return (
            <div className="d-flex align-items-center p-3" style={{ borderBottom: '2px solid #e1e1e1' }}>
                <div className="col-9 fw-bold">
                    {setMealItem.name}
                </div>

                <div className="col-3" style={{ textAlign: 'end' }}>
                    <div className="fs-6">from</div>
                    <MenuItemPrice
                        as="div"
                        className="fw-bold"
                        value={data.price}
                        isCurrencyFormat
                    />
                </div>
            </div>
        );
    }

    function renderModifierCategoryPanel(modifierCategory) {
        const key = modifierCategory.menuModifierCategoryGuid;

        if (!accordionButtonRefs[key]) accordionButtonRefs[key] = React.createRef();

        return (
            <ModifierCategoryPanel
                key={key}
                modifierCategory={modifierCategory}
                cartItemModifierItems={data.modifierItems}
                cartSetMealItem={data}
                activeEventKey={activeEventKeys.find(x => x === key) ?? null}
                openImageDialog={openImageDialog}
                toggleAccordion={toggleAccordion}
                handleModifierItemChange={handleModifierItemChange}
                accordionButtonRef={accordionButtonRefs[key]}
            />
        );
    }

    return (
        <BottomSheetDialog
            isOpen
            closeDialog={handleCancel}
            footer={
                <Footer
                    qty={data.quantity}
                    maxQty={maxQty}
                    price={data.subTotal}
                    form={DocumentIdConstants.SetMealItemEditForm}
                    buttonText={isAdd ? 'Add Item' : 'Update Item'}
                    handleMinus={handleMinus}
                    handleAdd={handleAdd}
                />
            }
        >
            <form id={DocumentIdConstants.SetMealItemEditForm} onSubmit={handleSubmit}>
                {/* Header */}
                <div
                    className="apps-ui-menu-item-component-header with-background is-header d-flex flex-column position-sticky top-0 bg-white py-2 px-3"
                    style={{ zIndex: 1 }}
                >
                    <div className="d-flex justify-content-center mt-2">
                        <SwipeableHintIcon useDarkBackground />
                    </div>

                    <div className="d-flex align-items-center text-primary">
                        <BackButton
                            className="ion ion-md-close fs-1 me-1"
                            style={{ padding: '7px 11px 6px' }}
                            onClick={handleCancel}
                        />

                        <span
                            className="fw-bold"
                            style={{
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden'
                            }}
                        >
                            {data.name}
                        </span>
                    </div>
                </div>

                {/* Content */}
                {renderDetailContent()}

                <Accordion
                    alwaysOpen
                    defaultActiveKey={activeEventKeys}
                    onSelect={onAccordionSelect}
                >
                    {setMealItem.modifierCategories.map(renderModifierCategoryPanel)}
                </Accordion>

                <div style={{ height: '50px' }} />
            </form>
        </BottomSheetDialog>
    );
}

const hocs = [
    withFormData,
    withOrderService
];

export default ComponentUtils.compose(hocs)(SetMealItemForm);