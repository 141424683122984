import React, { useState, useRef } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import AccordionButton from 'components/AccordionButton';
import Overlay from 'components/Overlay';
import * as OrderStatusConstants from 'data/orderStatusConstant';
import withOrderService from 'hocs/withOrderService';
import * as ComponentUtils from 'utils/component';
import * as EventUtils from 'utils/eventUtils';
import * as NumberUtils from 'utils/numberUtils';

const Footer = ({ orders, cart, settings }) => {
    const { localCurrencySymbol, currencyFormat, serviceChargeRate } = settings;
    const [activeEventKey, setActiveEventKey] = useState(null);

    const accordionButtonRef = useRef(null);
    const accordionHeaderRef = useRef(null);

    let totalQuantity = 0, serviceCharge = 0, tax = 0, totalAmountExTax = 0, roundingAdjustment = 0, finalTotal = 0;
    for (const order of orders) {
        // Rejected, cancelled and paid orders are excluded
        if (order.status === OrderStatusConstants.WaitingToAccept ||
            order.status === OrderStatusConstants.PendingPayment ||
            order.status === OrderStatusConstants.Accepted) {
            totalQuantity += order.totalQuantity;
            serviceCharge += order.serviceCharge;
            tax += order.tax;
            totalAmountExTax += order.totalAmountExTax;
        }
    }

    const round = (value) => NumberUtils.roundNumberFromFormat(value, currencyFormat);

    const netTotal = round(totalAmountExTax + tax + serviceCharge);
    finalTotal = round(cart.calculateFinalTotal(netTotal));
    roundingAdjustment = round(finalTotal - netTotal);

    function toggleAccordion() {
        accordionButtonRef.current.click();

        if (activeEventKey) accordionHeaderRef.current.style.removeProperty('border-bottom');
        else accordionHeaderRef.current.style.borderBottom = '1px solid var(--grey-300)';
    }

    function renderSummary() {
        return (
            <div className="d-flex flex-column fs-5 px-3 py-2">
                {renderLine('Subtotal (ex): ', totalAmountExTax)}
                {tax !== 0 && renderLine('Tax: ', tax)}
                {serviceCharge !== 0 && renderLine(`Service Charge (${serviceChargeRate}%): `, serviceCharge)}
                {renderLine('Rounding Adjustment: ', roundingAdjustment)}
            </div>
        );

        function renderLine(title, value) {
            return (
                <div className="d-flex mb-1">
                    <div className="col-8">{title}</div>
                    <div className="col-4" style={{ textAlign: 'end' }}>{`${localCurrencySymbol} ${NumberUtils.formatNumber(value, currencyFormat)}`}</div>
                </div>
            );
        }
    }

    return (
        <div className="d-flex flex-column position-sticky bottom-0">
            <Accordion onSelect={eventKey => setActiveEventKey(eventKey)}>
                {/* Accordion Header */}
                <div
                    className="d-flex align-items-center fw-bold px-3"
                    style={{ height: '50px', borderTop: '1px solid var(--grey-300)' }}
                    onClick={toggleAccordion}
                    ref={accordionHeaderRef}
                >
                    <div className="text-primary">{`${totalQuantity} Items`}</div>

                    <div className="d-flex flex-grow-1 align-items-center justify-content-end">
                        <Overlay
                            trigger="click"
                            placement="top"
                            popoverText="The final total is an estimate and may differ slightly when you pay at the counter."
                        >
                            <div className="col-1 icon-icon-info text-muted" onClick={EventUtils.cancelEventPropagation} />
                        </Overlay>

                        <span className="text-muted fs-6 me-2">FINAL TOTAL:</span>
                        <span className="fs-3">{`${localCurrencySymbol} ${NumberUtils.formatNumber(finalTotal, currencyFormat)}`}</span>
                    </div>

                    <div className="ms-2">
                        <AccordionButton
                            eventKey='0'
                            activeEventKey={activeEventKey}
                            ref={accordionButtonRef}
                        />
                    </div>
                </div>

                {/* Accordion Body */}
                <Accordion.Collapse eventKey="0">
                    {renderSummary()}
                </Accordion.Collapse>
            </Accordion>
        </div>
    );
};

const hocs = [
    withOrderService
];

const FooterWithHocs = ComponentUtils.compose(hocs)(Footer);

export { FooterWithHocs as OrderedTabFooter };