import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { pageRoutes } from 'utils/routes';
import ErrorBoundary from './ErrorBoundary';
import Layout from './Layout';

class Router extends Component {
    render() {
        return (
            <Layout>
                <ErrorBoundary>
                    <Switch>
                        {pageRoutes.map(route =>
                            <Route
                                path={route.path}
                                exact
                                render={props => <route.component {...props} />}
                                key={route.path}
                            />
                        )}
                    </Switch>
                </ErrorBoundary>
            </Layout>
        );
    }
}

export default Router;