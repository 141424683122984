import { formatNumber as dxFormatNumber } from 'devextreme/localization';

export function formatNumber(value, format) {
    return dxFormatNumber(value, format);
}

export function getDecimalFormat(decimal, fixedSize) {
    const digit = fixedSize ? '0' : '#';
    return '#,##0.' + digit.repeat(decimal);
}

export function roundNumber(value, precision) {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
}

export function roundNumberFromFormat(value, format) {
    const decimals = format.length - 6;
    return roundNumber(value, decimals < 0 ? 0 : decimals);
}

export function compareNumber(valueA, valueB) {
    //  -1 : if value1 < value2
    //   0 : if value1 = value2
    //   1 : if value1 > value2
    return (valueA > valueB) - (valueA < valueB);
}