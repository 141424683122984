/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import BackButton from 'components/BackButton';
import * as ZIndexUtils from 'utils/zIndexUtils';

const Dialog = ({ isOpen, title, children, footer, fullScreen, onOpened, closeDialog, ...rest }) => {
    const modalRef = useRef(null);

    const modalCss = css`
        .modal-dialog {
            max-width: var(--max-width);
        }
    `;

    const modalFullScreenCss = css`
        .modal-dialog {
            min-height: 100%;
            margin: auto;
        }
        
        .modal-dialog > .modal-content {
            min-height: 100%;
        }
    `;

    const modalNotFullScreenCss = css`
        .modal-dialog > .modal-content {
            max-height: 80vh;
        }
    `;

    const modalCssList = [modalCss];
    if (fullScreen) modalCssList.push(modalFullScreenCss);
    else modalCssList.push(modalNotFullScreenCss);

    function onDialogOpened() {
        ZIndexUtils.updateZIndexWithClassName('.modal-backdrop');
        ZIndexUtils.updateZIndexWithComponent(modalRef.current.dialog);

        if (onOpened) {
            onOpened();
        }
    }

    return (
        <Modal
            dialogClassName="modal-dialog-scrollable"
            css={modalCssList}
            show={isOpen}
            onHide={closeDialog}
            onShow={onDialogOpened}
            enforceFocus={false}
            ref={modalRef}
            {...rest}
        >
            {title &&
                <Modal.Header className="align-items-center justify-content-center" style={{ height: '50px' }}>
                    <BackButton className="ion-ios-arrow-back position-absolute start-0 fs-1 ps-3" onClick={closeDialog} />
                    <span className="fw-bold">{title}</span>
                </Modal.Header>
            }

            <Modal.Body className="p-0">
                {children}
            </Modal.Body>

            {footer && footer}
        </Modal>
    );
}

export default Dialog;