const cache = {};

export function createImagePlaceholder(text) {
    const firstLetter = getTextFirstLetter(text);
    if (cache[firstLetter]) return cache[firstLetter];

    const size = 500; // use a default size for the image src, the display will be controlled by img styling

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = canvas.height = size;

    ctx.fillStyle = '#fafafa';
    ctx.fillRect(0, 0, size, size);

    ctx.fillStyle = '#ccc';
    ctx.textBaseline = 'middle';
    ctx.textAlign = 'center';
    ctx.font = `${size / 2}px Roboto`;
    ctx.fillText(firstLetter, (size / 2), (size / 2));

    const result = canvas.toDataURL();

    cache[firstLetter] = result;

    return result;
}

function getTextFirstLetter(text) {
    return text.substring(0, 1).toUpperCase();
}