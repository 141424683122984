import React, { Component } from 'react';
import withSettings from 'hocs/withSettings';
import * as HomePageErrorCodes from 'pages/Home/data/errorCode';
import OrderContext from 'ui/context/OrderContext';
import * as OrderApi from 'ui/services/orderApi';
import { getDisplayName } from 'utils/component';
import * as ErrorMessage from 'utils/errorMessage';
import * as RoutesUtils from 'utils/routesUtils';
import { pagePaths } from 'utils/routes';

// Note : This HOC is encapsulated inside withSettings HOC
export default function withOrderService(WrappedComponent) {
    class WithOrderService extends Component {
        render() {
            const { settings } = this.props;
            const { tableNo, isQRCodeStatic, isPreview, orderServiceType, shareCart, useEPayment, cart } = this.context;

            return (
                <WrappedComponent
                    tableNo={tableNo}
                    isQRCodeStatic={isQRCodeStatic}
                    isPreview={isPreview}
                    orderServiceType={orderServiceType}
                    shareCart={shareCart}
                    useEPayment={useEPayment}
                    cart={cart}
                    orderService={new OrderService(this.context, settings)}
                    {...this.props}
                />
            );
        }
    }

    WithOrderService.displayName = `withOrderService(${getDisplayName(WrappedComponent)})`;
    WithOrderService.contextType = OrderContext;

    return withSettings(WithOrderService);
}

class OrderService {
    constructor(context, settings) {
        this.context = context;
        this.settings = settings;
    }

    getOrders() {
        return new Promise((resolve, reject) => {
            OrderApi.get()
                .then(result => {
                    resolve(result);
                })
                .catch(error => {
                    reject(ErrorMessage.fetchError(error));
                });
        });
    }

    getJoinedUserCount() {
        return new Promise((resolve, reject) => {
            OrderApi.getJoinedUserCount(this.context.qrCode)
                .then(result => {
                    resolve(result);
                })
                .catch(error => {
                    reject(ErrorMessage.fetchError(error));
                });
        });
    }

    leaveUserSession() {
        return new Promise((resolve, reject) => {
            OrderApi.leaveUserSession()
                .then(() => {
                    resolve();
                })
                .catch(error => {
                    reject(ErrorMessage.fetchError(error));
                });
        });
    }

    startOrder(shareCart, isHost, routeOptions) {
        return new Promise((resolve, reject) => {
            const input = {
                qrCodeId: this.context.qrCode,
                shareCart,
                isHost
            };

            OrderApi.startOrder(input)
                .then(result => {
                    if (result.isQRCodeInvalid) {
                        RoutesUtils.redirectToPage(pagePaths.QRCodeInvalid);
                    } else if (result.isQRCodeExpired) {
                        RoutesUtils.redirectToHomePageWithError({ errorCode: HomePageErrorCodes.QRCodeExpired });
                    } else if (!result.isShareCartSessionFound) {
                        // Note : Start order successfully, ready to navigate user to Menu page
                        this.context.startOrder(result, routeOptions);
                    }

                    resolve(result);
                })
                .catch(error => {
                    reject(ErrorMessage.fetchError(error));
                });
        });
    }

    addCartItem(cartItemInput) {
        const cart = this.context.cart;
        const { enableTax, defaultTaxCode, defaultTaxRate } = this.settings;

        cart.recalculateCartItemDerivedValues(cartItemInput);

        const input = {
            cartItem: cartItemInput,
            enableTax,
            defaultTaxCode,
            defaultTaxRate
        };

        return new Promise((resolve, reject) => {
            OrderApi.addCartItem(input)
                .then(cartItem => {
                    cart.addCartItem(cartItem);
                    resolve(cartItem);
                })
                .catch(error => {
                    reject(ErrorMessage.fetchError(error));
                });
        });
    }

    updateCartItem(cartItemInput) {
        const cart = this.context.cart;
        const { enableTax, defaultTaxCode, defaultTaxRate } = this.settings;

        cart.recalculateCartItemDerivedValues(cartItemInput);

        const input = {
            cartItem: cartItemInput,
            enableTax,
            defaultTaxCode,
            defaultTaxRate
        };

        return new Promise((resolve, reject) => {
            OrderApi.updateCartItem(input)
                .then(cartItem => {
                    cart.updateCartItem(cartItem);
                    resolve(cartItem);
                })
                .catch(error => {
                    reject(ErrorMessage.cartItemError(error));
                });
        });
    }

    updateCartItemQuantity(cartItemId, isAdd) {
        const cart = this.context.cart;

        const input = {
            cartItemId,
            isAdd
        };

        return new Promise((resolve, reject) => {
            OrderApi.updateCartItemQuantity(input)
                .then(cartItem => {
                    if (cartItem.quantity === 0) {
                        cart.removeCartItem(cartItem.cartItemId);
                    } else {
                        cart.updateCartItem(cartItem);
                    }
                    resolve();
                })
                .catch(error => {
                    reject(ErrorMessage.cartItemError(error));
                });
        })
    }

    removeCartItem(cartItemId) {
        const cart = this.context.cart;

        return new Promise((resolve, reject) => {
            OrderApi.removeCartItem(cartItemId)
                .then(() => {
                    cart.removeCartItem(cartItemId);
                    resolve();
                })
                .catch(error => {
                    reject(ErrorMessage.cartItemError(error));
                });
        });
    }

    confirmOrder(paymentGatewayChannelId, payLaterAtCounter) {
        const cart = this.context.cart;
        const { roundingOption, serviceChargeAndTaxInclusive, enableServiceCharge, serviceChargeRate, enableTax } = this.settings;

        const input = {
            roundingOption,
            serviceChargeAndTaxInclusive,
            enableServiceCharge,
            serviceChargeRate,
            enableTax,
            paymentGatewayChannelId,
            payLaterAtCounter
        };

        return new Promise((resolve, reject) => {
            OrderApi.confirmOrder(input)
                .then(result => {
                    cart.clearCart();
                    resolve(result);
                })
                .catch(error => {
                    reject(ErrorMessage.fetchError(error));
                });
        });
    }

    makePayment(orderId, paymentGatewayChannelId, payLaterAtCounter, validateHasAnyOngoingPayment) {
        const input = {
            orderId,
            paymentGatewayChannelId,
            payLaterAtCounter,
            validateHasAnyOngoingPayment
        };

        return new Promise((resolve, reject) => {
            OrderApi.makePayment(input)
                .then(result => {
                    resolve(result);
                })
                .catch(error => {
                    reject(ErrorMessage.fetchError(error));
                });
        });
    }
}