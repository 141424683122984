import * as Session from './session';
import * as RoutesUtils from './routesUtils';
import * as HomePageErrorCodes from 'pages/Home/data/errorCode';

export function getData(url) {
    return request(url)
        .then(handleResponse)
        .then(response => response.json());
}

export function postData(url, data) {
    return request(url, {
        method: 'POST',
        body: JSON.stringify(data)
    }).then(handleResponse);
}

export function putData(url, data) {
    return request(url, {
        method: 'PUT',
        body: JSON.stringify(data)
    }).then(handleResponse);
}

export function deleteData(url) {
    return request(url, {
        method: 'DELETE'
    }).then(handleResponse);
}

function request(url, options) {
    const fetchOptions = {
        ...options,
        headers: {
            'Content-Type': 'application/json',
            'X-TABLE-SESSION-ID': Session.getTableSessionId(),
            'X-USER-ID': Session.getUserId(),
            'X-SHARE-CART': Session.getShareCart()
        }
    };

    return fetch(url, fetchOptions);
}

function handleResponse(response) {
    if (response.headers) {
        const version = response.headers.get('x-app-version');

        if (version) {
            window.GLOBAL_Version = version;
        }
    }

    if (response.ok) {
        return response;
    } else {
        return response.json()
            .then(data => {
                if (data.isSessionClosed) {
                    RoutesUtils.redirectToHomePageWithError({ errorCode: HomePageErrorCodes.SessionClosed }, data.message);
                } else if (data.isRestaurantClosed) {
                    RoutesUtils.redirectToHomePageWithError({ errorCode: HomePageErrorCodes.RestaurantClosed }, data.message);
                }

                throw new ApiException(response.status, data.message);
            })
            .catch(error => {
                if (error.status) throw error;
                else throw new ApiException(response.status);
            });
    }
}

function ApiException(status, message) {
    this.status = status;
    this.message = message ? message : `Request failed with status ${status}`;
}

ApiException.prototype.toString = function () {
    return this.message;
}