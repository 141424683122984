import React from 'react';
import BottomSheetDialog from 'components/BottomSheetDialog';
import Button from 'components/Button';
import EmptyLine from 'components/EmptyLine';
import HintText from 'components/HintText';
import Icon from 'components/Icon';
import * as ServiceTypeConstants from 'data/serviceTypeConstant';
import withOrderService from 'hocs/withOrderService';
import MenuItemPrice from 'Menu/components/MenuItemPrice';
import * as ComponentUtils from 'utils/component';

const ItemsAlreadyInCartDialog = ({ menuItem, addItem, updateItem, closeDialog, orderServiceType, cart }) => {
    const cartItems = cart.items.filter(x => x.menuItemGuid === menuItem.menuItemGuid);

    function renderInCartItems(cartItem, index, isLastCartItem) {
        let customizationText = '';

        if (cartItem.menuSetMealGuid) {
            customizationText = cartItem.setMealItems.map(x => `${x.name} x${x.quantity}`).join(', ');
        } else {
            customizationText = cartItem.modifierItems.map(x => x.description).join(', ');
        }

        return (
            <div key={cartItem.cartItemId} className="d-flex flex-column">
                <div className="d-flex p-3">
                    <div className="flex-grow-1 d-flex flex-column me-3" style={{ overflowWrap: 'anywhere' }}>
                        <span className="fw-bold mb-1">
                            {`${index + 1}. ${cartItem.name}`}
                        </span>

                        {customizationText && <HintText className="mb-1">{customizationText}</HintText>}
                        {cartItem.remark && <HintText className="mb-1">{`"${cartItem.remark}"`}</HintText>}

                        {orderServiceType === ServiceTypeConstants.DineIn
                            && cartItem.serviceType === ServiceTypeConstants.TakeAway &&
                            <HintText className="mb-1">
                                Take Away
                                <span className="icon-icon-bag ms-1"></span>
                            </HintText>
                        }
                    </div>

                    <div className="d-flex flex-column align-items-end justify-content-between ms-auto" style={{ textWrap: 'nowrap' }}>
                        <Button
                            className="fs-5 mb-2"
                            primary
                            outline
                            onClick={() => updateItem(cartItem.cartItemId)}
                        >
                            <Icon name="fas fa-pencil-alt" label="Edit" />
                        </Button>

                        <span className="text-primary mb-2">{`x${cartItem.quantity}`}</span>

                        <MenuItemPrice
                            as="div"
                            value={cartItem.subTotal}
                            prefixSmallFont
                        />
                    </div>
                </div>

                {!isLastCartItem && <EmptyLine />}
            </div>
        );
    }

    return (
        <BottomSheetDialog
            isOpen
            title="Items Already In Cart"
            closeDialog={closeDialog}
            footer={
                <div
                    className="apps-general-box-shadow d-flex align-items-center justify-content-center p-3"
                    style={{ borderTop: '1px solid var(--grey-300)' }}
                >
                    <div
                        className="flex-grow-1 d-flex align-items-center justify-content-center text-white bg-primary py-2"
                        style={{ borderRadius: '6px', cursor: 'pointer' }}
                        onClick={addItem}
                    >
                        <Icon name="fas fa-plus" label="Add New" />
                    </div>
                </div>
            }
        >
            {cartItems.map((x, index) => renderInCartItems(x, index, index === cartItems.length - 1))}
        </BottomSheetDialog>
    );
}

const hocs = [
    withOrderService
];

export default ComponentUtils.compose(hocs)(ItemsAlreadyInCartDialog);