import React, { Component } from 'react';
import classNames from 'classnames';
import withSettings from 'hocs/withSettings';
import * as ComponentUtils from 'utils/component';
import { getDayOfWeekText } from '../data/dayOfWeekText';

class RestaurantOperatingDayDisplay extends Component {
    buildOperatingDayContents() {
        const { settings } = this.props;
        const { restaurantOperatingDays } = settings;

        // Note : Sort RestaurantOperatingDay from Monday to Sunday
        const sortedRestaurantOperatingDays = restaurantOperatingDays.sort((a, b) => {
            if (a.day === 0) return 1;

            return a.day - b.day;
        })

        const contents = [];

        for (const [index, restaurantOperatingDay] of sortedRestaurantOperatingDays.entries()) {
            const isFirstItem = index === 0;
            const isToday = new Date().getDay() === restaurantOperatingDay.day;

            contents.push(
                <div
                    key={`restaurant-operating-day-${restaurantOperatingDay.day}`}
                    className={`d-flex align-items-center fs-5 ${isToday ? 'fw-bold' : ''} ${isFirstItem ? '' : 'mt-1'}`}
                >
                    <span>
                        {getDayOfWeekText(restaurantOperatingDay.day)}
                    </span>

                    <span className="flex-grow-1 text-end ms-3">
                        {restaurantOperatingDay.isOpen ? restaurantOperatingDay.formattedOperatingHour : 'Closed'}
                    </span>
                </div>
            );
        }

        return contents;
    }

    render() {
        const { className } = this.props;
        const classes = classNames('d-flex flex-column', className);

        const operatingDayContents = this.buildOperatingDayContents();

        return (
            <div className={classes}>
                {operatingDayContents}
            </div>
        );
    }
}

const hocs = [
    withSettings
];

export default ComponentUtils.compose(hocs)(RestaurantOperatingDayDisplay);