import React from 'react';

// Note : This icon is used for BottomSheetDialog implementation
const SwipeableHintIcon = ({ useDarkBackground }) =>
    <span
        style={{
            width: '36px',
            height: '5px',
            background: useDarkBackground ? '#767676' : '#fff',
            borderRadius: '20px'
        }}
    />;

export default SwipeableHintIcon;