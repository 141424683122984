export function fetchError(error) {
    if (error.status) {
        return error.message;
    } else {
        return 'Network connection failed, please try again. Detail: "' + error + '"';
    }
}

export function cartItemError(error) {
    if (error.status) {
        return error.status === 404 ? 'Item not found.' : error.message;
    } else {
        return 'Network connection failed, please try again. Detail: "' + error + '"';
    }
}