import React from 'react';
import Button from 'components/Button';
import HintText from 'components/HintText';
import Image from 'components/Image';

const EmptyItem = ({ isOrdered, closeDialog }) => {
    let imageSrc = '', title = '', description = '';

    if (isOrdered) {
        imageSrc = '/img/empty-order-logo.png';
        title = `Ooops... You don't have any orders`;
        description = 'Go back to menu to checkout some items and confirm order.';
    } else {
        imageSrc = '/img/empty-cart-logo.png';
        title = `  Oops... Your cart is empty`;
        description = 'Go back to menu to checkout some items.';
    }

    return (
        <div
            className="position-absolute d-flex flex-column align-items-center justify-content-center w-100"
            style={{
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
            }}
        >
            <Image
                src={imageSrc}
                style={{ objectFit: 'contain' }}
                width="200px"
                height="200px"
            />

            <span className="fw-bold mt-3">{title}</span>

            <HintText className="mt-3">{description}</HintText>

            <Button
                primary
                className="mt-4 px-4"
                onClick={closeDialog}
            >
                Go to Menu
            </Button>
        </div>
    );
}

export default EmptyItem;