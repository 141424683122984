export default class Menu {
    constructor(menu) {
        this.categories = menu ? menu.categories : [];

        this.resultCache = {
            findMenuItem: {}
        };

        this.findMenuItem = this.findMenuItem.bind(this);
    }

    findMenuItem(menuItemGuid) {
        const cache = this.resultCache.findMenuItem;

        if (!cache[menuItemGuid]) {
            cache[menuItemGuid] = this.categories
                .map(category => category.items)
                .flat()
                .find(menuItem => menuItem.menuItemGuid === menuItemGuid);
        }

        return cache[menuItemGuid];
    }
}