import React, { Component } from 'react';
import UserContext from 'ui/context/UserContext';
import { getDisplayName } from 'utils/component';

export default function withUser(WrappedComponent) {
    class WithUser extends Component {
        render() {
            const { user } = this.context;

            return (
                <WrappedComponent
                    user={user}
                    {...this.props}
                />
            );
        }
    }

    WithUser.displayName = `WithUser(${getDisplayName(WrappedComponent)})`;
    WithUser.contextType = UserContext;

    return WithUser;
}