import React, { Component, useEffect, useState } from 'react';
import withRouter from 'hocs/withRouter';

function ErrorBoundary(props) {
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        // Set to no error when location changed
        if (hasError) setHasError(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.location])

    return (
        <ErrorBoundaryInner
            hasError={hasError}
            setHasError={setHasError}
        >
            {props.children}
        </ErrorBoundaryInner>
    );
}

class ErrorBoundaryInner extends Component {
    static getDerivedStateFromError() {
        return { hasError: true };
    }

    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidUpdate(prevProps) {
        if (!this.props.hasError && prevProps.hasError) {
            this.setState({ hasError: false });
        }
    }

    componentDidCatch() {
        this.props.setHasError(true);
    }

    render() {
        if (this.state.hasError) {
            return (
                <div
                    className="text-danger"
                    style={{
                        padding: '50px',
                        textAlign: 'center',
                        fontSize: '24px'
                    }}
                >
                    Something went wrong. Please refresh the page and try again.
                </div>
            );
        } else {
            return this.props.children;
        }
    }
}

export default withRouter(ErrorBoundary);