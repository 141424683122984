import * as Dialog from 'devextreme/ui/dialog';
import * as Session from './session';
import * as RoutesUtils from './routesUtils';
import * as HomePageErrorCodes from 'pages/Home/data/errorCode';

function checkVersion() {
    if (window.APP_SETTINGS_Version && window.GLOBAL_Version && window.APP_SETTINGS_Version !== window.GLOBAL_Version) {
        showVersionUpdatedMessage();
    } else {
        startCheckVersion();
    }
}

export function startCheckVersion() {
    setTimeout(checkVersion, 10000);
}

function showVersionUpdatedMessage() {
    const dlg = Dialog.custom({
        showTitle: true,
        title: 'Version updated',
        dragEnabled: false,
        messageHtml: '<div style="max-width:500px">Please click Reload button below to reload.</div>',
        buttons: [{
            text: 'Reload',
            type: 'default',
            onClick: () => window.location.reload()
        }]
    });

    return dlg.show();
}

function checkStorageData() {
    if (isValidNumber(Session.getAccountBookId()) &&
        isValidNumber(Session.getTableSessionId())) {
        startCheckStorageData();
    } else {
        RoutesUtils.redirectToHomePageWithError({ errorCode: HomePageErrorCodes.SessionClosed }, 'Session closed');
    }
}

function isValidNumber(value) {
    const intValue = parseInt(value);
    return isNaN(intValue) ? false : true;
}

export function startCheckStorageData() {
    setTimeout(checkStorageData, 2000);
}