import React, { useState } from 'react';
import HintText from 'components/HintText';

const RemarkPanel = ({ value, onValueChanged }) => {
    const [isOpened, setIsOpened] = useState(!!value);

    function openRemarkPanel() {
        if (!isOpened) {
            setIsOpened(true);
        }
    }

    return (
        <div
            className="apps-ui-menu-item-component-customize-panel d-flex flex-column p-3"
            onClick={openRemarkPanel}
        >
            {/* Title */}
            <div className={`d-flex align-items-center fw-bold ${isOpened ? '' : 'text-primary'}`}>
                {!isOpened &&
                    <span className="fas fa-plus me-2" />
                }

                <span>Special Instructions</span>
            </div>

            {/* Detail */}
            {isOpened &&
                <>
                    <HintText className="pb-1">
                        Provide additional information to be noted by the kitchen.
                    </HintText>

                    <textarea
                        className="form-control fs-5 px-2"
                        style={{ resize: 'none' }}
                        rows="2"
                        maxLength={100}
                        autoFocus={!value}
                        value={value ?? ''}
                        onChange={e => onValueChanged(e.target.value)}
                    />
                </>
            }
        </div>
    );
}

export default RemarkPanel;