import React, { useEffect, useRef } from 'react';
import BackButton from 'components/BackButton';
import SwipeableHintIcon from 'components/SwipeableHintIcon';
import MenuItemImage from 'Menu/components/MenuItemImage';
import MenuItemName from 'Menu/components/MenuItemName';
import * as EventUtils from 'utils/eventUtils';

const withBackgroundClass = 'with-background';

const Header = ({ imageSrc, name, displayCode, showAppBarBg, closeDialog, openImageDialog }) => {
    const hasImgSrc = !!imageSrc;

    useEffect(() => {
        if (!hasImgSrc || showAppBarBg) {
            appBarRef.current.classList.add(withBackgroundClass);
        } else {
            appBarRef.current.classList.remove(withBackgroundClass);
        }
    }, [hasImgSrc, showAppBarBg]);

    const appBarRef = useRef();

    function handleClose(e) {
        EventUtils.stopPropagation(e);
        closeDialog();
    }

    return (
        <>
            {/* App Bar */}
            <div
                className={`apps-ui-menu-item-component-header is-header d-flex flex-column position-fixed text-primary w-100 px-3`}
                style={{
                    height: '55px',
                    borderTopLeftRadius: '15px',
                    borderTopRightRadius: '15px',
                    zIndex: 1
                }}
                onClick={() => hasImgSrc && !showAppBarBg && openImageDialog(imageSrc)}
                ref={appBarRef}
            >
                <div className="d-flex justify-content-center mt-2">
                    <SwipeableHintIcon
                        useDarkBackground={showAppBarBg || !hasImgSrc}
                    />
                </div>

                <div className={`d-flex align-items-center text-primary ${hasImgSrc && !showAppBarBg ? 'justify-content-between' : ''}`}>
                    <BackButton
                        className={`apps-ui-menu-item-component-header-back-button ion ion-md-close rounded-circle fs-1 me-1 ${hasImgSrc && showAppBarBg ? '' : 'bg-white'}`}
                        style={{ padding: '7px 11px 6px' }}
                        onClick={handleClose}
                    />

                    {(showAppBarBg || !hasImgSrc) &&
                        <span
                            className={`fw-bold ${showAppBarBg ? 'apps-general-shimmer ' : ''}`}
                            style={{
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden'
                            }}
                        >
                            <MenuItemName name={name} displayCode={displayCode} />
                        </span>
                    }

                    {(hasImgSrc && !showAppBarBg) &&
                        <span
                            className="bg-white icon-icon-zoom-in rounded-circle fs-1"
                            style={{ padding: '7px' }}
                            onClick={() => openImageDialog(imageSrc, name)}
                        />
                    }
                </div>
            </div>

            {/* Image Panel */}
            {hasImgSrc &&
                <div
                    className="position-relative d-flex flex-column"
                    style={{ height: '200px' }}
                    onClick={() => openImageDialog(imageSrc, name)}
                >
                    <MenuItemImage
                        className="position-absolute w-100 h-100 top-0"
                        style={{ objectFit: 'cover' }}
                        imageSrc={imageSrc}
                        name={name}
                    />
                </div>
            }
        </>
    );
}

export default Header;