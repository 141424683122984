import React from 'react';

const QuantityIcon = ({ value }) =>
    <span
        className="px-1 fw-bold text-primary fs-6"
        style={{ background: 'var(--primary-light)', borderRadius: '6px' }}
    >
        {`x${value}`}
    </span>

export default QuantityIcon;