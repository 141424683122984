import React, { Component } from 'react';
import SettingsContext from 'ui/context/SettingsContext';
import { getDisplayName } from 'utils/component';

export default function withSettings(WrappedComponent, fetchSettings = false) {
    class WithSettings extends Component {
        componentDidMount() {
            if (fetchSettings) {
                this.context.fetchSettings();
            }
        }

        render() {
            return (
                <WrappedComponent
                    {...this.context}
                    {...this.props}
                />
            );
        }
    }

    WithSettings.displayName = `WithSettings(${getDisplayName(WrappedComponent)})`;
    WithSettings.contextType = SettingsContext;

    return WithSettings;
}