import React from 'react';
import withSettings from 'hocs/withSettings';
import * as ComponentUtils from 'utils/component';
import * as NumberUtils from 'utils/numberUtils';

const MenuItemPrice = ({
    as: Element,
    className,
    style,
    value,
    prefix,
    suffix,
    prefixSmallFont,
    suffixSmallFont,
    isCurrencyFormat,
    settings }) => {
    const { localCurrencySymbol, currencyFormat, priceFormat } = settings;

    const format = isCurrencyFormat ? currencyFormat : priceFormat;
    const priceText = NumberUtils.formatNumber(value, format);

    function getPrefixText() {
        let text = localCurrencySymbol;
        if (prefix) text = `${prefix} ${text}`;
        return text;
    };

    function renderText(text, isSmallFont) {
        return (
            <span style={{ fontSize: isSmallFont ? '10px' : undefined }}>
                {text}
            </span>
        );
    }

    return (
        <Element className={className} style={style}>
            {/* Prefix */}
            {renderText(getPrefixText(), prefixSmallFont)}

            &nbsp;

            {/* Value */}
            {priceText}

            {/* Suffix */}
            {suffix && (
                <>
                    &nbsp;
                    {renderText(suffix, suffixSmallFont)}
                </>
            )}
        </Element>
    );
}

const hocs = [
    withSettings
];

export default ComponentUtils.compose(hocs)(MenuItemPrice);