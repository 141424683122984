import React, { Component } from 'react';
import withOrderService from 'hocs/withOrderService';
import withBlockUI from 'hocs/withBlockUI';
import withMenu from 'hocs/withMenu';
import * as ComponentUtils from 'utils/component';
import EditForm from '../components/EditForm';
import ItemsAlreadyInCartDialog from '../components/ItemsAlreadyInCartDialog';

const ActionType = {
    Add: 'Add',
    Update: 'Update'
};

class MenuItemFunction extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // EditForm
            formData: {},
            formMode: {},
            showDialog: false,
            isSetMeal: false,

            // ItemsAlreadyInCartDialog
            showItemsAlreadyInCartDialog: false
        };

        this.actionEvent = {};

        this.openDialog = this.openDialog.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.openItemsAlreadyInCartDialog = this.openItemsAlreadyInCartDialog.bind(this);
        this.closeItemsAlreadyInCartDialog = this.closeItemsAlreadyInCartDialog.bind(this);
        this.addItem = this.addItem.bind(this);
        this.addItemFromItemsAlreadyInCartDialog = this.addItemFromItemsAlreadyInCartDialog.bind(this);
        this.doAddItem = this.doAddItem.bind(this);
        this.updateItem = this.updateItem.bind(this);
        this.updateItemFromItemsAlreadyInCartDialog = this.updateItemFromItemsAlreadyInCartDialog.bind(this);
        this.doUpdateItem = this.doUpdateItem.bind(this);
        this.cancelEdit = this.cancelEdit.bind(this);
        this.saveRecord = this.saveRecord.bind(this);
    }

    setAddMode() {
        this.setState({ formMode: { isAdd: true } });
    }

    setUpdateMode() {
        this.setState({ formMode: { isUpdate: true } });
    }

    setIsSetMeal(menuItem) {
        this.setState({ isSetMeal: !!menuItem.setMeal });
    }

    actionCompleted() {
        this.props.onActionCompleted(this.actionEvent);
        this.resetActionEvent();

        if (this.state.showDialog) {
            this.closeDialog();
        }
    }

    actionError(error) {
        this.props.onActionError(error);
        this.resetActionEvent();

        if (this.state.showDialog) {
            this.closeDialog();
        }
    }

    setActionEvent(actionType) {
        let actionMessage = null;

        if (actionType === ActionType.Add) {
            actionMessage = 'Item added successfully.';
        } else if (actionType === ActionType.Update) {
            actionMessage = 'Item updated successfully.';
        }

        const actionEvent = {
            actionMessage
        };

        this.actionEvent = actionEvent;

        return actionEvent;
    }

    resetActionEvent() {
        this.actionEvent = {};
    }

    openDialog() {
        this.setState({ showDialog: true });
    }

    closeDialog() {
        this.setState({ showDialog: false });
    }

    openItemsAlreadyInCartDialog() {
        this.setState({ showItemsAlreadyInCartDialog: true });
    }

    closeItemsAlreadyInCartDialog() {
        this.setState({ showItemsAlreadyInCartDialog: false });
    }

    addItem(menuItemGuid) {
        const { menu, cart } = this.props;

        const menuItem = menu.findMenuItem(menuItemGuid);
        if (menuItem == null) return;

        const hasCartItem = cart.items.some(x => x.menuItemGuid === menuItemGuid);

        if (hasCartItem) {
            this.setState({ formData: { menuItem } });
            this.openItemsAlreadyInCartDialog();
        } else {
            this.doAddItem(menuItem);
        }
    }

    addItemFromItemsAlreadyInCartDialog() {
        const { formData } = this.state;
        const { menuItem } = formData;

        this.doAddItem(menuItem);
        this.closeItemsAlreadyInCartDialog();
    }

    doAddItem(menuItem) {
        const { cart } = this.props;

        const cartItem = cart.buildCartItem(menuItem);

        this.setAddMode();

        this.setState({
            formData: {
                menuItem,
                cartItem
            }
        });

        this.openDialog();
        this.setIsSetMeal(menuItem);
    }

    updateItem(cartItemId) {
        const { menu, cart } = this.props;

        const cartItem = cart.findCartItem(cartItemId);
        if (!cartItem) return;

        const menuItem = menu.findMenuItem(cartItem.menuItemGuid);
        if (!menuItem) {
            this.actionError('Item not available in your menu.');
            return;
        }

        this.doUpdateItem(menuItem, cartItem);
    }

    updateItemFromItemsAlreadyInCartDialog(cartItemId) {
        const { cart } = this.props;
        const { formData } = this.state;
        const { menuItem } = formData;

        const cartItem = cart.findCartItem(cartItemId);
        if (!cartItem) return;

        this.doUpdateItem(menuItem, cartItem);
        this.closeItemsAlreadyInCartDialog();
    }

    doUpdateItem(menuItem, cartItem) {
        this.setUpdateMode();

        this.setState({
            formData: {
                menuItem,
                cartItem
            }
        });

        this.openDialog();
        this.setIsSetMeal(menuItem);
    }

    cancelEdit() {
        this.closeDialog();
    }

    saveRecord(_, current) {
        const { orderService } = this.props;

        this.props.blockUI();

        if (this.state.formMode.isAdd) {
            orderService.addCartItem(current)
                .then(_ => {
                    this.props.unblockUI();
                    this.setActionEvent(ActionType.Add);
                    this.actionCompleted();
                })
                .catch(errorMessage => {
                    this.props.unblockUI();
                    this.actionError(errorMessage);
                });
        } else {
            orderService.updateCartItem(current)
                .then(_ => {
                    this.props.unblockUI();
                    this.setActionEvent(ActionType.Update);
                    this.actionCompleted();
                })
                .catch(errorMessage => {
                    this.props.unblockUI();
                    this.actionError(errorMessage);
                });
        }
    }

    render() {
        const { formData, formMode, showDialog, showItemsAlreadyInCartDialog, isSetMeal } = this.state;

        return (
            <>
                {this.props.children({ addItem: this.addItem, updateItem: this.updateItem })}

                {showDialog &&
                    <EditForm
                        initialData={formData.cartItem}
                        menuItem={formData.menuItem}
                        isAdd={!!formMode.isAdd}
                        isSetMeal={isSetMeal}
                        openImageDialog={this.openImageDialog}
                        onCancel={this.cancelEdit}
                        onSubmit={this.saveRecord}
                    />
                }

                {showItemsAlreadyInCartDialog &&
                    <ItemsAlreadyInCartDialog
                        menuItem={formData.menuItem}
                        addItem={this.addItemFromItemsAlreadyInCartDialog}
                        updateItem={this.updateItemFromItemsAlreadyInCartDialog}
                        closeDialog={this.closeItemsAlreadyInCartDialog}
                    />
                }
            </>
        );
    }
}

const hocs = [
    withOrderService,
    withMenu,
    withBlockUI
];

const MenuFunctionWithHocs = ComponentUtils.compose(hocs)(MenuItemFunction);

function withMenuItemFunction(WrappedComponent) {
    // eslint-disable-next-line react/no-multi-comp
    class WithMenuItemFunction extends Component {
        constructor(props) {
            super(props);

            this.state = {
                onActionError: null,
                onActionCompleted: null
            };
        }

        transformToPromise(action) {
            return new Promise((resolve, reject) => {
                this.setState(
                    {
                        onActionError: reject,
                        onActionCompleted: resolve
                    },
                    () => action()
                );
            });
        }

        render() {
            return (
                <MenuFunctionWithHocs
                    {...this.state}
                    {...this.props}
                >
                    {({ addItem, updateItem }) => (
                        <WrappedComponent
                            addItem={menuItemGuid => this.transformToPromise(() => addItem(menuItemGuid))}
                            updateItem={menuItemGuid => this.transformToPromise(() => updateItem(menuItemGuid))}
                            {...this.props}
                        />
                    )}
                </MenuFunctionWithHocs>
            );
        }
    }

    WithMenuItemFunction.displayName = `WithMenuItemFunction(${ComponentUtils.getDisplayName(WrappedComponent)})`;

    return WithMenuItemFunction;
}

export default withMenuItemFunction;