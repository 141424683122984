import * as MenuItemTagConstants from './menuItemTagConstant';

const menuItemTagText = [
    { text: 'icon-icon-chili', value: MenuItemTagConstants.Spicy, color: '#ff0000' },
    { text: 'icon-icon-recommended', value: MenuItemTagConstants.Recommended, color: '#005dfc' },
    { text: 'icon-icon-garlic', value: MenuItemTagConstants.Vegetable, color: '#6f9940' },
    { text: 'icon-icon-chicken', value: MenuItemTagConstants.Chicken, color: '#eca237' },
    { text: 'icon-icon-pig', value: MenuItemTagConstants.Pork, color: '#f9a195' },
    { text: 'icon-icon-cow', value: MenuItemTagConstants.Beef, color: '#650e00' }
];

export function getMenuItemTag(value) {
    const item = menuItemTagText.find(x => x.value === value);
    return item;
}