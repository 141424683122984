import React, { useState, useRef } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import AccordionButton from 'components/AccordionButton';
import Button from 'components/Button';
import EmptyLine from 'components/EmptyLine';
import HintText from 'components/HintText';
import Icon from 'components/Icon';
import * as OrderStatusConstants from 'data/orderStatusConstant';
import { getOrderStatusItem } from 'data/orderStatusText';
import withOrderService from 'hocs/withOrderService';
import withUser from 'hocs/withUser';
import MenuItemImage from 'Menu/components/MenuItemImage';
import MenuItemName from 'Menu/components/MenuItemName';
import MenuItemPrice from 'Menu/components/MenuItemPrice';
import * as ComponentUtils from 'utils/component';
import * as DateTimeUtils from 'utils/dateTimeUtils';
import AlertBanner from '../Shared/AlertBanner';
import EmptyItem from '../Shared/EmptyItem';
import ItemDetailUIBuilder from '../../utils/itemDetailUIBuilder';

const OrderedTab = ({
    orders,
    lastSyncOrderTimeStamp,
    isPendingInitialSyncOrder,
    closeOrderDialog,
    makePayment,
    syncOrders,
    orderServiceType,
    useEPayment,
    user,
    settings }) => {
    const { userId } = user;
    const { allowPayLaterAtCounter } = settings;
    const [activeEventKey, setActiveEventKey] = useState(null);

    const accordionHeaderRefs = useRef({});
    const accordionButtonRefs = useRef({});

    function renderOrderPanel(order) {
        const orderStatusItem = getOrderStatusItem(order.status);

        let orderStatusText = orderStatusItem.text;
        if (order.status === OrderStatusConstants.Accepted && order.items.some(x => x.quantity !== x.originalQuantity)) {
            orderStatusText = 'Order Partially Accepted';
        }

        return (
            <div
                key={order.orderId}
                className="apps-general-box-shadow mb-5 mx-3"
                style={{ borderRadius: '12px' }}
                onClick={() => accordionButtonRefs.current[order.orderId].click()}
                ref={el => accordionHeaderRefs.current[order.orderId] = el}
            >
                {/* Accordion Header */}
                <div className="d-flex flex-column">
                    {/* Order Status */}
                    <div
                        className="d-flex align-items-center justify-content-center fs-5 py-2"
                        style={{
                            color: orderStatusItem.color,
                            background: orderStatusItem.background,
                            borderRadius: '12px 12px 0 0'
                        }}
                    >
                        <Icon name={orderStatusItem.icon} label={orderStatusText} />
                    </div>

                    {/* Order Details */}
                    <div className="d-flex">
                        <div className="d-flex flex-column p-3" style={{ borderRight: '2px solid var(--grey-200)', whiteSpace: 'nowrap' }}>
                            <span className="fw-bold mb-1">{order.orderNo}</span>
                            <HintText className="mb-1">{DateTimeUtils.dateToString(DateTimeUtils.stringToDate(order.createdTimeStamp), false)}</HintText>
                            <HintText>{`Created by ${order.userId === userId ? 'you' : 'others'}`}</HintText>
                        </div>

                        <div className="d-flex flex-column flex-grow-1 p-3">
                            <div className="d-flex mb-3">
                                <div className="d-flex flex-column me-3">
                                    <HintText>Total</HintText>
                                    <MenuItemPrice
                                        as="span"
                                        className="fs-5"
                                        value={order.netTotal}
                                        prefixSmallFont
                                        isCurrencyFormat
                                    />
                                </div>

                                <div className="d-flex flex-column me-3">
                                    <HintText>Items</HintText>
                                    <span className="fs-5">{order.totalQuantity}</span>
                                </div>

                                <div className="ms-auto">
                                    <AccordionButton
                                        eventKey={order.orderId}
                                        activeEventKey={activeEventKey}
                                        ref={el => accordionButtonRefs.current[order.orderId] = el}
                                    />
                                </div>
                            </div>

                            {order.status === OrderStatusConstants.PendingPayment &&
                                <div className="d-flex mt-auto">
                                    <Button
                                        primary
                                        className="fs-5 ms-auto"
                                        onClick={e => makePayment(e, order.orderId)}
                                    >
                                        <Icon name="fas fa-cash-register" label="Make Payment" />
                                    </Button>
                                </div>
                            }
                        </div>
                    </div>
                </div>

                {/* Accordion Body */}
                <Accordion.Collapse eventKey={order.orderId} className="pb-2">
                    <>
                        <EmptyLine />
                        <div className="px-3 mt-3" style={{ fontWeight: 600 }}>Order Items</div>
                        {order.items.map(renderOrderItem)}
                        {renderSummary(order)}
                    </>
                </Accordion.Collapse>
            </div>
        );
    }

    function renderOrderItem(item) {
        const itemDetailUIBuilder = ItemDetailUIBuilder.createFromOrder(item, orderServiceType);

        return (
            <React.Fragment key={item.orderItemId}>
                <div className="d-flex flex-column px-3">
                    {item.quantity !== item.originalQuantity &&
                        <div
                            className="d-flex align-items-center justify-content-center fs-6 p-1 mt-3"
                            style={{
                                background: '#fff6e2',
                                borderRadius: '6px',
                                fontWeight: 600
                            }}
                        >
                            {item.quantity === 0
                                ? 'Cancelled by cashier.'
                                : `Qty changed by cashier from ${item.originalQuantity} to ${item.quantity}.`
                            }
                        </div>
                    }

                    <div className="d-flex mt-3">
                        <MenuItemImage
                            className="border"
                            style={{ borderRadius: '6px' }}
                            imageSrc={item.image}
                            name={item.name}
                            width='70px'
                            height='70px'
                            isLazyLoading
                        />

                        <div className="flex-grow-1 d-flex flex-column mx-3">
                            <div className="d-flex fs-5 fw-bold">
                                <span>
                                    <MenuItemName name={item.name} displayCode={item.displayCode} />
                                </span>
                            </div>

                            {itemDetailUIBuilder.build()}
                        </div>

                        <div className="col-2 d-flex flex-column text-end">
                            <MenuItemPrice
                                as="span"
                                className="fs-5"
                                value={item.subTotal}
                                isCurrencyFormat
                            />
                            <span className="text-primary fw-bold mt-auto ms-2">
                                {`x${item.quantity}`}
                            </span>
                        </div>
                    </div>

                    <EmptyLine className="mt-3" />
                </div>
            </React.Fragment>
        );
    }

    function renderSummary(order) {
        return (
            <div className="d-flex flex-column px-3 pt-3">
                {renderLine('Total Items', order.totalQuantity, false)}
                {renderLine('Subtotal (ex)', order.totalAmountExTax)}
                {order.serviceCharge !== 0 && renderLine(`Service Charge (${order.serviceChargeRate}%)`, order.serviceCharge)}
                {order.tax !== 0 && renderLine('Tax', order.tax)}
                {renderLine('Net Total', order.netTotal, true, true)}
            </div>
        );

        function renderLine(title, value, isCurrency = true, isEmphasis = false) {
            return (
                <div className={`d-flex mb-1 ${isEmphasis ? 'fw-bold' : 'text-muted fs-5'}`}>
                    <div className="col-8">{title}</div>
                    <div className="col-4" style={{ textAlign: 'end' }}>
                        {isCurrency
                            ? <MenuItemPrice as="span" value={value} isCurrencyFormat />
                            : value
                        }
                    </div>
                </div>
            );
        }
    }

    return (
        <>
            {useEPayment && orders.length > 0 &&
                <AlertBanner>
                    {allowPayLaterAtCounter
                        ? 'Order will be sent to counter immediately if you choose to pay later at counter. Otherwise, it will only be prepared once e-payment is made.'
                        : 'Order will only be prepared once e-payment is made.'
                    }
                </AlertBanner>
            }

            <HintText className="d-flex align-items-center justify-content-center py-3">
                <span>{`Last Update ${DateTimeUtils.dateToString(lastSyncOrderTimeStamp, true)}`}</span>
                <span className="fas fa-sync-alt ms-2" onClick={syncOrders} />
            </HintText>

            {isPendingInitialSyncOrder
                ? null
                : orders.length === 0
                    ? <EmptyItem isOrdered closeDialog={closeOrderDialog} />
                    : <Accordion onSelect={eventKey => setActiveEventKey(eventKey)}>
                        {orders.map(renderOrderPanel)}
                    </Accordion>
            }
        </>
    );
};

const hocs = [
    withOrderService,
    withUser
];

export default ComponentUtils.compose(hocs)(OrderedTab);