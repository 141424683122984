import * as ServiceTypeConstants from './serviceTypeConstant';

const serviceTypeText = [
    { text: 'Dine In', value: ServiceTypeConstants.DineIn },
    { text: 'Take Away', value: ServiceTypeConstants.TakeAway }
];

export function getServiceTypeText(value) {
    const item = serviceTypeText.find(x => x.value === value);
    return item ? item.text : '';
}