import React from 'react';
import withSettings from 'hocs/withSettings';
import * as ComponentUtils from 'utils/component';

const LandingPage = ({ settings }) => {
    const { viewLandingPageImage } = settings;

    return (
        <div
            className="apps-page-home-landing-page"
            style={{
                width: '100vw',
                height: '100vh',
                // eslint-disable-next-line no-dupe-keys
                height: '100dvh',
                maxWidth: 'var(--max-width)',
                background: `${`url(${viewLandingPageImage})`} no-repeat center / cover`
            }}
        />
    )
}

const hocs = [
    withSettings
];

export default ComponentUtils.compose(hocs)(LandingPage);