/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { Component } from 'react';

class TabContent extends Component {
    constructor(props) {
        super(props);

        this.tabs = [];
        this.panes = [];

        this.show = this.show.bind(this);
        this.renderTab = this.renderTab.bind(this);
        this.renderPane = this.renderPane.bind(this);
    }

    createTabKey(index) {
        const { tabPrefix = 'tab' } = this.props;
        return `${tabPrefix}${index}`;
    }

    show(tabKey) {
        const { onTabChanged } = this.props;

        let tab = this.tabs.find(x => x.tabKey === tabKey);
        let pane = this.panes.find(x => x.tabKey === tabKey);

        if (tab && pane) {
            tab = tab.ref.current;
            pane = pane.ref.current;

            this.tabs.forEach(({ ref }) => {
                ref.current.classList.remove('active');
            });

            this.panes.forEach(({ ref }) => {
                ref.current.classList.remove('show', 'active');
                ref.current.style.display = 'none';
            });

            tab.classList.add('active');
            pane.classList.add('show', 'active');
            pane.style.display = 'block';

            if (onTabChanged) onTabChanged(tabKey);
        }
    }

    isActiveTab(index) {
        const { tabPrefix, defaultTabKey } = this.props;
        const tabKey = tabPrefix + index;

        if (defaultTabKey) {
            return defaultTabKey === tabKey;
        } else {
            return index === 0;
        }
    }

    renderTab(tabPage, index) {
        const tabKey = this.createTabKey(index);
        const ref = React.createRef();

        this.tabs.push({ tabKey, ref });

        const isActiveTab = this.isActiveTab(index);

        return (
            <li
                key={index}
                className="nav-item"
            >
                <button
                    className={`nav-link text-body h-100 ${isActiveTab ? 'active' : ''}`}
                    style={{ fontWeight: 600 }}
                    type="button"
                    onClick={() => this.show(tabKey)}
                    ref={ref}
                >
                    {tabPage.props.title}
                </button>
            </li>
        );
    }

    renderPane(tabPage, index) {
        const tabKey = this.createTabKey(index);
        const ref = React.createRef();

        this.panes.push({ tabKey, ref });

        const style = { padding: tabPage.props.noPadding ? 0 : null };
        const isActiveTab = this.isActiveTab(index);

        return (
            <div
                className={`fade ${isActiveTab ? 'show active' : ''}`}
                style={{ display: isActiveTab ? 'block' : 'none' }}
                id={tabKey}
                key={index}
                ref={ref}
            >
                <div
                    className="card-body"
                    style={style}
                >
                    {tabPage.props.children}
                </div>
            </div>
        );
    }

    render() {
        const { children, noBorder, minHeight, sticky, boxShadow, top, height, justify } = this.props;

        const navTabStyle = {
            position: sticky ? 'sticky' : undefined,
            top: top || undefined,
            height: height || undefined,
            zIndex: 5,
            background: 'white'
        };

        const tabContentStyle = {
            border: noBorder ? 'none' : undefined,
            minHeight: minHeight || undefined
        };

        const tabPages = children ? children.filter(item => item) : [];

        this.tabs = [];
        this.panes = [];

        return (
            <div className="nav-tabs-top">
                <ul
                    className={`nav nav-tabs transformer-tabs ${boxShadow ? 'apps-general-box-shadow' : ''} ${justify ? 'nav-justified' : ''}`}
                    style={navTabStyle}
                >
                    {tabPages.map(this.renderTab)}
                </ul>
                <div className="tab-content" style={tabContentStyle}>
                    {tabPages.map(this.renderPane)}
                </div>
            </div>
        );
    }
}

export default TabContent;