import React from 'react';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import classNames from 'classnames';

const AccordionButton = React.forwardRef((props, ref) => {
    const { primary, background = false, className, eventKey, activeEventKey, ...rest } = props;

    const handleClick = useAccordionButton(eventKey);
    const isCurrentEventKey = activeEventKey === eventKey;

    const classes = classNames(
        'apps-component-accordion-button fas fa-chevron-down d-inline-flex align-items-center justify-content-center p-1',
        {
            'show': isCurrentEventKey,
            'rounded-circle': background,
            'text-primary': primary
        },
        className
    );

    return (
        <span
            className={classes}
            style={{ background: background ? 'var(--primary-light)' : undefined }}
            onClick={handleClick}
            ref={ref}
            {...rest}
        />
    );
});

export default AccordionButton;