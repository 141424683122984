import * as NumberUtils from './numberUtils';

export default class SettingValues {
    constructor(obj) {
        Object.assign(this, obj);
        this.calculateDerivedValues();
    }

    calculateDerivedValues() {
        this.currencyFormat = NumberUtils.getDecimalFormat(this.currencyDecimal, true);
        this.priceFormat = NumberUtils.getDecimalFormat(2, true);
    }
}