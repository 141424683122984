import React, { Component } from 'react';
import BackButton from 'components/BackButton';
import Dialog from 'components/Dialog';
import TabPanel from 'components/TabPanel';
import TabContent from 'components/TabContent';
import withBlockUI from 'hocs/withBlockUI';
import withOrderService from 'hocs/withOrderService';
import * as ComponentUtils from 'utils/component';
import * as Toast from 'utils/toast';
import { InCartTabFooter } from './InCartTab/Footer';
import InCartTab from './InCartTab/InCartTab';
import { OrderedTabFooter } from './OrderedTab/Footer';
import OrderedTab from './OrderedTab/OrderedTab';

class OrderDialogInner extends Component {
    constructor(props) {
        super(props);

        const { tabKey, isPreview, forwardedRef } = props;

        this.state = {
            tabKey,
            orders: [],
            lastSyncOrderTimeStamp: new Date(),
            isPendingInitialSyncOrder: !isPreview && this.isOrderedTab(tabKey)
        };

        this.tabRef = React.createRef();

        forwardedRef.current = this;

        this.onTabChanged = this.onTabChanged.bind(this);
        this.syncOrders = this.syncOrders.bind(this);
        this.resyncOrders = this.resyncOrders.bind(this);
    }

    componentDidMount() {
        const { isPreview } = this.props;
        const { isPendingInitialSyncOrder } = this.state;

        if (isPreview) return;

        // Do initial sync order job
        if (isPendingInitialSyncOrder) {
            this.syncOrders();
        }

        // Create timer to sync order
        this.createSyncOrderTimer();
    }

    componentWillUnmount() {
        this.deleteSyncOrderTimer();
    }

    isInCartTab(tabKey) {
        return tabKey === 'order0';
    }

    isOrderedTab(tabKey) {
        return tabKey === 'order1';
    }

    createSyncOrderTimer() {
        if (this.timer) return;

        this.timer = setInterval(() => {
            if (this.isOrderedTab(this.state.tabKey)) { // Note : tabKey must be accessed via the latest component state
                this.resyncOrders();
            }
        }, 3000);
    }

    deleteSyncOrderTimer() {
        if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
        }
    }

    onTabChanged(tabKey) {
        const { isPreview } = this.props;

        if (this.isOrderedTab(tabKey)) {
            // Note : onTabChanged will be triggered even if the same tab is selected
            const isPendingInitialSyncOrder = !isPreview && !this.isOrderedTab(this.state.tabKey);

            this.setState({ tabKey, isPendingInitialSyncOrder });

            if (isPendingInitialSyncOrder) {
                this.syncOrders();
            }
        } else {
            this.setState({ tabKey });
        }
    }

    syncOrders() {
        const { isPreview, orderService } = this.props;

        if (isPreview) return;

        this.props.blockUI();

        this.deleteSyncOrderTimer();

        orderService.getOrders()
            .then(orders => {
                this.props.unblockUI();
                this.createSyncOrderTimer();
                this.setState({
                    orders,
                    lastSyncOrderTimeStamp: new Date(),
                    isPendingInitialSyncOrder: false
                });
            })
            .catch(errorMessage => {
                this.props.unblockUI();
                this.createSyncOrderTimer();
                this.setState({ isPendingInitialSyncOrder: false });
                Toast.showErrorMessage(errorMessage);
            });
    }

    resyncOrders() {
        const { orderService } = this.props;

        orderService.getOrders()
            .then(orders => {
                this.setState({ orders, lastSyncOrderTimeStamp: new Date() });
            })
            .catch(errorMessage => {
                Toast.showErrorMessage(errorMessage);
            });
    }

    render() {
        const { cart, closeOrderDialog, confirmOrder, makePayment } = this.props;
        const { tabKey, orders, lastSyncOrderTimeStamp, isPendingInitialSyncOrder } = this.state;

        return (
            <Dialog
                isOpen
                fullScreen
                footer={(
                    <>
                        {this.isInCartTab(tabKey) && cart.hasAnyItems() && <InCartTabFooter confirmOrder={confirmOrder} />}
                        {this.isOrderedTab(tabKey) && orders.length > 0 && <OrderedTabFooter orders={orders} />}
                    </>
                )}
            >
                <>
                    <div
                        className="d-flex align-items-center justify-content-center position-sticky top-0 bg-white"
                        style={{ height: '50px', zIndex: 5 }}
                    >
                        <BackButton className="position-absolute ion-ios-arrow-back start-0 fs-1 ps-3" onClick={closeOrderDialog} />
                        <span className="fw-bold">Your Cart</span>
                    </div>

                    <TabContent
                        tabPrefix="order"
                        defaultTabKey={this.isOrderedTab(tabKey) ? 'order1' : undefined}
                        top='50px'
                        height='45px'
                        justify
                        sticky
                        boxShadow
                        onTabChanged={this.onTabChanged}
                        ref={this.tabRef}
                    >
                        <TabPanel title="In Cart" noPadding>
                            <InCartTab
                                closeOrderDialog={closeOrderDialog}
                            />
                        </TabPanel>

                        <TabPanel title="Ordered" noPadding>
                            <OrderedTab
                                orders={orders}
                                lastSyncOrderTimeStamp={lastSyncOrderTimeStamp}
                                isPendingInitialSyncOrder={isPendingInitialSyncOrder}
                                closeOrderDialog={closeOrderDialog}
                                makePayment={makePayment}
                                syncOrders={this.syncOrders}
                            />
                        </TabPanel>
                    </TabContent>
                </>
            </Dialog>
        );
    }
}

const hocs = [
    withBlockUI,
    withOrderService
];

const OrderDialogWithHocs = ComponentUtils.compose(hocs)(OrderDialogInner);

const OrderDialog = React.forwardRef((props, ref) => <OrderDialogWithHocs {...props} forwardedRef={ref} />);

export default OrderDialog;