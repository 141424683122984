export function updateTheme() {
    // Update Appwork Theme
    replaceStylesheet('theme-settings-bootstrap-css', `/vendor/css/bootstrap.css`);

    // Update DevExtreme Theme
    replaceStylesheet('theme-settings-dev-extreme', `/css/dx.generic.custom-scheme.css`);

    // Update Apps Theme 
    replaceStylesheet('theme-settings-apps', `/css/apps.css`);
}

function replaceStylesheet(className, href) {
    let curLink = document.querySelector(`.${className}`);
    const newLink = document.createElement('link');

    newLink.className = className;
    newLink.onload = () => {
        if (!curLink.parentNode) {
            curLink = document.querySelector(`.${className}`);
        }

        curLink.parentNode.removeChild(curLink);
    };
    newLink.setAttribute('rel', 'stylesheet');
    newLink.setAttribute('type', 'text/css');
    newLink.setAttribute('href', href);

    curLink.parentNode.insertBefore(newLink, curLink.nextSibling);
}