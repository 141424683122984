function getLargestZIndex(component = null) {
    // Find the largest zIndex between dialog, offcanvas and popver
    const elements = document.querySelectorAll('[role="dialog"], [role="tooltip"]');
    let largestZIndex = 1089;

    for (const element of elements) {
        const zIndex = parseFloat(window.getComputedStyle(element).zIndex);

        if (component !== null && component === element) {
            return largestZIndex;
        }

        if (zIndex) {
            largestZIndex = Math.max(largestZIndex, zIndex);
        }
    }

    return largestZIndex;
}

export function updateZIndex(componentId) {
    const component = document.querySelector(`[id="${componentId}"]`);
    component.style.zIndex = getLargestZIndex() + 1;
}

export function updateZIndexWithComponent(component) {
    return component.style.zIndex = getLargestZIndex(component) + 1;
}

export function updateZIndexWithClassName(componentClassName) {
    const components = document.querySelectorAll(componentClassName);

    // Assign zIndex that appear at the bottom of the DOM
    if (components.length > 0) {
        const component = components[components.length - 1];
        component.style.zIndex = getLargestZIndex() + 1;
    }
}