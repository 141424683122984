import { toast } from 'react-toastify';

export function showErrorMessage(message) {
    toast.error(message);
}

export function showSuccessMessage(message) {
    toast.success(message);
}

export function showWarningMessage(message) {
    toast.warn(message);
}