import React, { Component } from 'react';
import withBlockUI from 'hocs/withBlockUI';
import withOrderService from 'hocs/withOrderService';
import * as ComponentUtils from 'utils/component';
import * as EventUtils from 'utils/eventUtils';
import * as MessageBox from 'utils/messageBox';
import * as Toast from 'utils/toast';
import PaymentChannelDialog from '../components/PaymentChannelDialog';
import OrderDialog from '../components/OrderDialog';

const ShowPaymentChannelDialogReason = {
    ConfirmOrder: 'ConfirmOrder',
    MakePayment: 'MakePayment'
};

export default function withOrderFunction(WrappedComponent) {
    class WithOrder extends Component {
        constructor(props) {
            super(props);

            this.state = {
                showOrderDialog: false,
                showPaymentChannelDialog: false,
                showPaymentChannelDialogReason: null,
                tabKey: null,
                orderIdForPayment: null
            };

            this.orderDialog = React.createRef();

            this.viewCart = this.viewCart.bind(this);
            this.viewOrder = this.viewOrder.bind(this);

            this.openOrderDialog = this.openOrderDialog.bind(this);
            this.closeOrderDialog = this.closeOrderDialog.bind(this);
            this.openPaymentChannelDialog = this.openPaymentChannelDialog.bind(this);
            this.closePaymentChannelDialog = this.closePaymentChannelDialog.bind(this);

            this.confirmOrder = this.confirmOrder.bind(this);
            this.makePayment = this.makePayment.bind(this);

            this.submitPaymentChannel = this.submitPaymentChannel.bind(this);

            this.doConfirmOrder = this.doConfirmOrder.bind(this);
            this.doMakePayment = this.doMakePayment.bind(this);
        }

        viewCart() {
            this.openOrderDialog();
            this.setState({ tabKey: 'order0' });
        }

        viewOrder() {
            this.openOrderDialog();
            this.setState({ tabKey: 'order1' });
        }

        openOrderDialog() {
            this.setState({ showOrderDialog: true });
        }

        closeOrderDialog() {
            this.setState({ showOrderDialog: false });
        }

        openPaymentChannelDialog(reason) {
            this.setState({
                showPaymentChannelDialog: true,
                showPaymentChannelDialogReason: reason
            });
        }

        closePaymentChannelDialog() {
            this.setState({
                showPaymentChannelDialog: false,
                showPaymentChannelDialogReason: null
            });
        }

        confirmOrder() {
            const { isPreview, useEPayment } = this.props;

            if (useEPayment) {
                this.openPaymentChannelDialog(ShowPaymentChannelDialogReason.ConfirmOrder);
            } else if (isPreview) {
                MessageBox.showMessage('You are not allowed to confirm order in preview mode.');
            } else {
                MessageBox.showConfirmMessage('Are you sure to confirm order?')
                    .then(result => {
                        if (!result.confirm) return;

                        this.doConfirmOrder();
                    });
            }
        }

        makePayment(e, orderId) {
            EventUtils.stopPropagation(e);

            this.openPaymentChannelDialog(ShowPaymentChannelDialogReason.MakePayment);
            this.setState({ orderIdForPayment: orderId });
        }

        submitPaymentChannel(data) {
            const { isPreview } = this.props;
            const { showPaymentChannelDialogReason } = this.state;
            const { paymentGatewayChannelId, payLaterAtCounter } = data;

            if (isPreview) {
                MessageBox.showMessage('You are not allowed to confirm order in preview mode.');
                return;
            }

            if (showPaymentChannelDialogReason === ShowPaymentChannelDialogReason.ConfirmOrder) {
                this.doConfirmOrder(paymentGatewayChannelId, payLaterAtCounter);
            } else if (showPaymentChannelDialogReason === ShowPaymentChannelDialogReason.MakePayment) {
                this.doMakePayment(true, paymentGatewayChannelId, payLaterAtCounter);
            }
        }

        doConfirmOrder(paymentGatewayChannelId = null, payLaterAtCounter = true) {
            const { orderService } = this.props;
            const { showPaymentChannelDialog } = this.state;

            this.props.blockUI();

            orderService.confirmOrder(paymentGatewayChannelId, payLaterAtCounter)
                .then(result => {
                    if (result.requestDataInJson && result.requestUrl) {
                        this.submitPaymentGatewayForm(result, () => {
                            this.props.unblockUI();
                            this.closePaymentChannelDialog();
                            this.orderDialog.current.tabRef.current.show('order1');
                        });
                    } else {
                        this.props.unblockUI();
                        Toast.showSuccessMessage('Order has been confirmed successfully.');
                        this.orderDialog.current.tabRef.current.show('order1');

                        if (showPaymentChannelDialog) {
                            this.closePaymentChannelDialog();
                        }
                    }
                })
                .catch(errorMessage => {
                    this.props.unblockUI();
                    Toast.showErrorMessage(errorMessage);
                });
        }

        async doMakePayment(validateHasAnyOngoingPayment, paymentGatewayChannelId, payLaterAtCounter) {
            const { orderService } = this.props;
            const { orderIdForPayment } = this.state;

            this.props.blockUI();

            try {
                const result = await orderService.makePayment(orderIdForPayment, paymentGatewayChannelId, payLaterAtCounter, validateHasAnyOngoingPayment);

                if (result.isOngoingPaymentFound) {
                    this.props.unblockUI();

                    const message = 'There\'s at least one ongoing payment for this order. Please check to avoid making duplicate payments.';
                    const messageBoxResult = await MessageBox.showConfirmMessage(message, '', false, 'Proceed', 'Cancel');
                    if (!messageBoxResult.confirm) return;

                    this.doMakePayment(false, paymentGatewayChannelId, payLaterAtCounter);
                } else if (result.requestDataInJson && result.requestUrl) {
                    this.submitPaymentGatewayForm(result, () => this.props.unblockUI());
                } else {
                    this.props.unblockUI();
                    this.closePaymentChannelDialog();
                    this.orderDialog.current.syncOrders();
                    Toast.showSuccessMessage('Order has been sent to counter successfully.');
                }
            } catch (errorMessage) {
                this.props.unblockUI();
                Toast.showErrorMessage(errorMessage);
            }
        }

        submitPaymentGatewayForm(data, onAfterSubmit) {
            const { requestDataInJson, requestUrl, requestName } = data;
            const requestData = JSON.parse(requestDataInJson);

            const form = document.createElement('form');
            form.setAttribute('method', 'post');
            form.setAttribute('action', requestUrl);

            if (requestName) {
                form.setAttribute("name", requestName);
            }

            for (const key in requestData) {
                const inputEl = document.createElement('input');
                inputEl.setAttribute('type', 'hidden');
                inputEl.setAttribute('name', key);
                inputEl.setAttribute('value', requestData[key] ? requestData[key] : ' ');

                form.appendChild(inputEl);
            }

            const submitInputEl = document.createElement('input');
            submitInputEl.setAttribute('type', 'submit');
            form.appendChild(submitInputEl);

            document.body.appendChild(form);

            form.onsubmit = () => {
                setTimeout(() => onAfterSubmit(), 5000); // Add litle time buffering for form submission

                return true;
            }

            submitInputEl.click();
        }

        render() {
            const { tabKey, showOrderDialog, showPaymentChannelDialog } = this.state;

            return (
                <>
                    <WrappedComponent
                        viewCart={this.viewCart}
                        viewOrder={this.viewOrder}
                        {...this.props}
                    />

                    {showOrderDialog &&
                        <OrderDialog
                            tabKey={tabKey}
                            closeOrderDialog={this.closeOrderDialog}
                            confirmOrder={this.confirmOrder}
                            makePayment={this.makePayment}
                            ref={this.orderDialog}
                        />
                    }

                    {showPaymentChannelDialog &&
                        <PaymentChannelDialog
                            closeDialog={this.closePaymentChannelDialog}
                            handleSubmit={this.submitPaymentChannel}
                        />
                    }
                </>
            );
        }
    }

    WithOrder.displayName = `WithOrder(${ComponentUtils.getDisplayName(WrappedComponent)})`;

    const hocs = [
        withBlockUI,
        withOrderService
    ];

    return ComponentUtils.compose(hocs)(WithOrder);
}