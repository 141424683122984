import React, { useState, useEffect } from 'react';

const SearchBar = ({ onSearch }) => {
    const [value, setValue] = useState('');

    useEffect(() => {
        const timer = setTimeout(() => onSearch(value), 500);
        return () => clearTimeout(timer);
    }, [value, onSearch]);

    return (
        <div className="w-100 px-3">
            <input
                className="apps-page-menu-search-bar form-control fs-6 pe-4"
                autoFocus
                type="text"
                placeholder="Search Menu"
                value={value}
                onChange={e => setValue(e.target.value)}
            />
        </div>
    );
}

export default SearchBar;