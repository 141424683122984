import React from 'react';

const Image = ({ isLazyLoading, altText, ...rest }) => {
    return (
        <img
            loading={isLazyLoading ? 'lazy' : 'eager'} // Note : loading attribute must be defined before src attribute else will have no effect in Firefox
            alt={altText ? altText : ''}
            {...rest}
        />
    );
}

export default Image;