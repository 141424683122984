import React from 'react';
import Badge from 'components/Badge';
import EmptyLine from 'components/EmptyLine';
import * as ServiceTypeConstants from 'data/serviceTypeConstant';
import QuantityIcon from '../components/Shared/QuantityIcon';

// Helper to build UI layout for cart / order item detail
export default class ItemDetailUIBuilder {
    constructor(item, orderServiceType, isOrderedTab) {
        this.orderServiceType = orderServiceType;
        this.isOrderedTab = isOrderedTab;

        this.data = this._buildItemModel(item);

        this.build = this.build.bind(this);
    }

    static createFromCart(item, orderServiceType) {
        return new ItemDetailUIBuilder(item, orderServiceType, false);
    }

    static createFromOrder(item, orderServiceType) {
        return new ItemDetailUIBuilder(item, orderServiceType, true);
    }

    build() {
        return (
            <>
                {this._buildCustomizeContents()}
                {this._buildRemarkContent()}
            </>
        );
    }

    _buildCustomizeContents() {
        // Build customize contents
        const customizeContents = [];

        // - Take away content
        if (this.orderServiceType === ServiceTypeConstants.DineIn &&
            this.data.serviceType === ServiceTypeConstants.TakeAway &&
            (this.isOrderedTab)) {
            customizeContents.push(
                <Badge key="take-away-label" className={`${this.data.isSetMeal ? 'mb-3' : ''}`}>
                    <span className="icon-icon-bag text-primary me-1" />
                    <span>Take Away</span>
                </Badge>
            );
        }

        // - MenuItem contents
        for (const modifierItem of this.data.modifierItems) {
            customizeContents.push(
                <Badge key={modifierItem.modifierItemId}>
                    {modifierItem.description}
                </Badge>
            );
        }

        // - SetMeal contents
        for (const [index, setMealItem] of this.data.setMealItems.entries()) {
            const isLastLine = index === this.data.setMealItems.length - 1;

            customizeContents.push(
                <React.Fragment key={setMealItem.setMealItemId}>
                    <div className="d-flex align-items-center fs-5 mt-2">
                        <span className="me-1">{setMealItem.name}</span>
                        {this.isOrderedTab
                            ? <span>{` x${setMealItem.quantity}`}</span>
                            : <QuantityIcon value={setMealItem.quantity} />
                        }
                    </div>

                    {setMealItem.modifierItems.length > 0 &&
                        <div className="d-flex flex-wrap gap-2 mt-2">
                            {setMealItem.modifierItems.map(x =>
                                <Badge key={x.modifierItemId}>
                                    {x.description}
                                </Badge>
                            )}
                        </div>
                    }

                    {!isLastLine && <EmptyLine className="mt-2" dashed />}
                </React.Fragment>
            );
        }

        return customizeContents.length > 0
            ? <div className={`${this.data.isSetMeal ? 'd-flex flex-column' : 'd-flex flex-wrap gap-2 mt-2'}`}>
                {customizeContents}
            </div>
            : null;
    }

    _buildRemarkContent() {
        if (this.data.remark) {
            return (
                <div className="d-flex fs-6 mt-2" style={{ overflowWrap: 'anywhere' }}>
                    {`"${this.data.remark}"`}
                </div>
            );
        }
    }

    _buildItemModel(item) {
        // Build Item Model from CartItem or OrderItem
        return {
            name: item.name,
            displayCode: item.displayCode,
            isSetMeal: item.menuSetMealGuid,
            modifierItems: item.modifierItems.map(modifierItem => ({
                modifierItemId: this.isOrderedTab ? modifierItem.orderItemModifierItemId : modifierItem.cartItemModifierItemId,
                description: modifierItem.description,
                quantity: modifierItem.quantity
            })),
            setMealItems: item.setMealItems.map(setMealItem => ({
                setMealItemId: this.isOrderedTab ? setMealItem.orderSetMealItemId : setMealItem.cartSetMealItemId,
                name: setMealItem.name,
                quantity: setMealItem.quantity,
                modifierItems: setMealItem.modifierItems.map(modifierItem => ({
                    modifierItemId: this.isOrderedTab ? modifierItem.orderSetMealItemModifierItemId : modifierItem.cartSetMealItemModifierItemId,
                    description: modifierItem.description,
                    quantity: modifierItem.quantity
                }))
            })),
            remark: item.remark,
            serviceType: item.serviceType
        };
    }
}