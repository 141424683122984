export const RazerVisaOrMasterCard = 1;
export const RazerTouchAndGo = 2;
export const RazerBoost = 3;
export const RazerGrabPay = 4;
export const RazerMaybankQRPay = 5;
export const RazerShopeePay = 6;

export const IPay88CreditCardMYR = 21;
export const IPay88TouchAndGo = 22;
export const IPay88Boost = 23;
export const IPay88GrabPay = 24;
export const IPay88MaybankQRPay = 25;
export const IPay88ShopeePay = 26;
export const IPay88PayPalMYR = 27;