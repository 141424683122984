function padZero(num, size) {
    let s = num + '';
    while (s.length < size) s = '0' + s;
    return s;
}

export function dateToString(date, dateExclusive) {
    const timeString = `${date.getHours()}:${padZero(date.getMinutes(), 2)}:${padZero(date.getSeconds(), 2)}`;
    if (dateExclusive) return timeString;

    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const dateString = `${padZero(date.getDate(), 2)} ${months[date.getMonth()]} ${date.getFullYear()}`;

    return dateString + ' ' + timeString;
}

export function stringToDate(dateString) {
    return new Date(dateString);
}

export function compareDateTime(dateString1, dateString2) {
    //  -1 : if date1 < date2
    //   0 : if date1 = date2
    //   1 : if date1 > date2

    const date1 = stringToDate(dateString1);
    const date2 = stringToDate(dateString2);

    if (date1 < date2) return -1;
    if (date1 > date2) return 1;
    return 0;
}