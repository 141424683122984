import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import * as DocumentIdConstants from 'data/documentIdConstant';
import withQuitOrder from 'ui/QuitOrder/hocs/withQuitOrder';
import * as ComponentUtils from 'utils/component';
import { pagePaths } from 'utils/routes';

const Layout = ({ history, children, openQuitOrderDialog }) => {
    useEffect(() => {
        const unblock = history.block((location, action) => {
            if (action === 'POP') {
                // Block navigation if any of the following conditions are met
                // - MessageBox opened
                const messageBoxDialogs = document.querySelectorAll('.dx-dialog');
                if (messageBoxDialogs.length > 0) return false;

                // - Dialog opened
                const dialogs = document.querySelectorAll('.modal-dialog');
                if (dialogs.length > 0) {
                    // - Close outermost layer
                    closeElementByBackButton(dialogs[dialogs.length - 1]);
                    return false;
                }

                // - Sidenav opened
                const sidenav = document.querySelector('.offcanvas-header');
                if (sidenav !== null) {
                    closeElementByBackButton(sidenav);
                    return false;
                }

                // - Back action from Home page
                if (history.location.pathname === pagePaths.Home) {
                    return false;
                }

                // - Back action from Menu page to Home page
                const redirectFromMenuToHomePage =
                    history.location.pathname === pagePaths.Menu &&
                    location.pathname === pagePaths.Home;

                if (redirectFromMenuToHomePage) {
                    openQuitOrderDialog();
                    return false;
                }
            } else {
                return true;
            }
        });

        return () => unblock();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    });

    function closeElementByBackButton(element) {
        const backButton = element.querySelector(`#${DocumentIdConstants.ComponentBackButton}`);
        if (backButton !== null) backButton.click();
    }

    return children;
}

const hocs = [
    withRouter,
    withQuitOrder
];

export default ComponentUtils.compose(hocs)(Layout);