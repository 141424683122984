import React from 'react';
import Icon from 'components/Icon';

const ContentWrapper = ({ iconName, iconLabel, children, ...rest }) => {
    return (
        <div
            className="d-flex flex-column p-3"
            style={{ borderBottom: '8px solid var(--grey-100)' }}
            {...rest}
        >
            <div className="d-flex align-items-center text-primary fs-3" style={{ fontWeight: 600 }}>
                <Icon name={iconName} label={iconLabel} />
            </div>

            {children}
        </div>
    );
};

export default ContentWrapper;