import React, { useEffect, useState } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Guid from 'devextreme/core/guid';
import HintText from 'components/HintText';
import * as ZIndexUtils from 'utils/zIndexUtils';

const Overlay = ({ trigger, placement, autoCloseTime, popoverText, children }) => {
    const [showPopover, setShowPopover] = useState(false);

    return (
        <OverlayTrigger
            rootClose
            trigger={trigger}
            placement={placement}
            overlay={
                <PopoverWrapper
                    autoCloseTime={autoCloseTime}
                    onClose={() => setShowPopover(false)}
                >
                    <HintText>{popoverText}</HintText>
                </PopoverWrapper>
            }
            show={showPopover}
            onToggle={e => setShowPopover(e)}
        >
            {children}
        </OverlayTrigger>
    );
}

export default Overlay;

const PopoverWrapper = React.forwardRef(({ popper, children, className, autoCloseTime, onClose, ...props }, ref) => {
    const popoverId = new Guid();

    useEffect(() => {
        ZIndexUtils.updateZIndex(popoverId);
        const timer = autoCloseTime && setTimeout(onClose, autoCloseTime);

        return () => timer && clearTimeout(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [children, popper, autoCloseTime, onClose]);

    const classes = className + ' p-2';

    return (
        <Popover
            id={popoverId}
            className={classes}
            ref={ref}
            {...props}
        >
            {children}
        </Popover>
    );
});