import React from 'react';
import BottomSheetDialog from 'components/BottomSheetDialog';
import EmptyLine from 'components/EmptyLine';
import MenuItemQuantityIcon from 'Menu/components/MenuItemQuantityIcon';

const MenuCategoryListingDialog = ({ menuCategories, activeMenuCategoryGuid, doNavScroll, closeDialog }) => {
    function onItemSelected(menuCategoryGuid) {
        closeDialog();
        doNavScroll(menuCategoryGuid);
    }

    return (
        <BottomSheetDialog
            title="Category"
            isOpen
            closeDialog={closeDialog}
        >
            <div className="d-flex flex-column">
                {menuCategories.map(x =>
                    <React.Fragment key={x.menuCategoryGuid}>
                        <div
                            className="d-flex flex-row-reverse align-items-center px-3 mt-3"
                            onClick={() => onItemSelected(x.menuCategoryGuid)}
                        >
                            {x.totalSelectedQty > 0 &&
                                <MenuItemQuantityIcon border>
                                    {`x${x.totalSelectedQty}`}
                                </MenuItemQuantityIcon>
                            }

                            <div
                                className={`flex-grow-1 ${activeMenuCategoryGuid === x.menuCategoryGuid ? 'text-primary' : ''}`}
                                style={{ fontWeight: 500 }}
                            >
                                {x.name}
                            </div>
                        </div>

                        <EmptyLine className="mt-2" />
                    </React.Fragment>
                )}
            </div>
        </BottomSheetDialog>
    );

};

export default MenuCategoryListingDialog;