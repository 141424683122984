import { Component } from 'react';
import { ToastContainer, Slide } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import BlockUI from 'components/BlockUI';
import * as DocumentIdConstants from 'data/documentIdConstant';
import WithRouter from 'hocs/withRouter';
import Router from 'layouts/Router';
import BlockUIContext from 'ui/context/BlockUIContext';
import MenuContext from 'ui/context/MenuContext';
import OrderContext from 'ui/context/OrderContext';
import SettingsContext from 'ui/context/SettingsContext';
import UserContext from 'ui/context/UserContext';
import Cart from 'ui/objects/Cart';
import Menu from 'ui/objects/Menu';
import User from 'ui/objects/User';
import * as ApplicationApi from 'ui/services/applicationApi';
import * as OrderApi from 'ui/services/orderApi';
import * as BackgroundTask from 'utils/backgroundTask';
import * as ColorUtils from 'utils/colorUtils';
import * as ErrorMessage from 'utils/errorMessage';
import { pagePaths } from 'utils/routes';
import * as RoutesUtils from 'utils/routesUtils';
import * as Session from 'utils/session';
import SettingValues from 'utils/SettingValues';
import * as Toast from 'utils/toast';

class App extends Component {
    constructor(props) {
        super(props);

        this.fetchChangeLogsTimer = null;
        this.lastCartItemChangeLogId = -1;
        this.lastUserSessionChangeLogId = -1;
        this.isPageVisible = true;

        this.onVisibilityChange = this.onVisibilityChange.bind(this);
        this.blockUI = this.blockUI.bind(this);
        this.unblockUI = this.unblockUI.bind(this);
        this.fetchSettings = this.fetchSettings.bind(this);
        this.startOrder = this.startOrder.bind(this);
        this.onCartUpdated = this.onCartUpdated.bind(this);
        this.onUserUpdated = this.onUserUpdated.bind(this);

        this.state = {
            settingsContextValue: {
                settings: new SettingValues({}),
                fetchSettings: this.fetchSettings
            },
            blockUIContextValue: {
                blocking: false,
                blockUI: this.blockUI,
                unblockUI: this.unblockUI
            },
            menuContextValue: {
                menu: new Menu()
            },
            userContextValue: {
                user: new User(Session.getUserId(), this.onUserUpdated)
            },
            orderContextValue: {
                userId: Session.getUserId(),
                qrCode: Session.getQRCode(),
                tableNo: Session.getTableNo(),
                isQRCodeStatic: Session.getIsQRCodeStatic(),
                isPreview: Session.getIsPreview(),
                orderServiceType: Session.getServiceType(),
                shareCart: Session.getShareCart(),
                useEPayment: Session.getUseEPayment(),
                cart: new Cart(null, this.onCartUpdated, null, Session.getServiceType()),
                startOrder: this.startOrder
            },
            isFetchingSettings: true,
            isFailedFetchingSettings: false
        };
    }

    componentDidMount() {
        BackgroundTask.startCheckVersion();
        document.addEventListener('visibilitychange', this.onVisibilityChange);
        this.fetchSettings();
    }

    componentDidUpdate() {
        if (this.props.location.pathname === pagePaths.Home && this.fetchChangeLogsTimer) {
            // Stop fetching ChangeLogs if redirect to Home page
            clearTimeout(this.fetchChangeLogsTimer);
        }
    }

    componentWillUnmount() {
        if (this.fetchChangeLogsTimer) clearTimeout(this.fetchChangeLogsTimer);
        document.removeEventListener('visibilitychange', this.onVisibilityChange);
    }

    onVisibilityChange() {
        if (document.visibilityState === 'hidden') {
            // Browser is hidden / switched to other tabs
            this.isPageVisible = false;
        } else {
            this.isPageVisible = true;
        }
    }

    blockUI() {
        this.setState({
            blockUIContextValue: {
                ...this.state.blockUIContextValue,
                blocking: true
            }
        });
    }

    unblockUI() {
        this.setState({
            blockUIContextValue: {
                ...this.state.blockUIContextValue,
                blocking: false
            }
        });
    }

    updateThemeColor(themeColor) {
        document.documentElement.style.setProperty('--primary', themeColor);
        document.documentElement.style.setProperty('--primary-dark', ColorUtils.generateColor(themeColor, false, 20));
        document.documentElement.style.setProperty('--primary-light', ColorUtils.generateColor(themeColor, true, 80));
    }

    fetchSettings() {
        const { qrCode } = this.state.orderContextValue;

        ApplicationApi.getSettings(qrCode)
            .then(settings => {
                if (settings.viewThemeColorCode) {
                    this.updateThemeColor(settings.viewThemeColorCode);
                }

                this.setState({
                    settingsContextValue: {
                        ...this.state.settingsContextValue,
                        settings: new SettingValues(settings)
                    },
                    isFetchingSettings: false
                });
            })
            .catch(error => {
                Toast.showErrorMessage(ErrorMessage.fetchError(error));

                this.setState({
                    isFetchingSettings: false,
                    isFailedFetchingSettings: true
                });
            });
    }

    startOrder(result, routeOptions) {
        const { settings } = this.state.settingsContextValue;
        const { orderServiceType } = this.state.orderContextValue;

        const { tableSessionId, shareCart, useEPayment } = result;
        let { menu, cart } = result;

        menu = new Menu(menu);
        cart = new Cart(cart, this.onCartUpdated, settings, orderServiceType);

        Session.setTableSessionId(tableSessionId);
        Session.setShareCart(shareCart);
        Session.setUseEPayment(useEPayment);

        BackgroundTask.startCheckStorageData();

        this.setState({
            menuContextValue: {
                ...this.state.menuContextValue,
                menu
            },
            orderContextValue: {
                ...this.state.orderContextValue,
                shareCart,
                useEPayment,
                cart
            }
        }, async () => {
            if (shareCart) {
                this.lastCartItemChangeLogId = -1;
                this.lastUserSessionChangeLogId = -1;
                await this.fetchChangeLogs(true);
            }

            RoutesUtils.navigateToPage(this.props.history, 'Menu', routeOptions);
        });
    }

    onCartUpdated() {
        this.setState({ orderContextValue: { ...this.state.orderContextValue } });
    }

    onUserUpdated() {
        this.setState({ userContextValue: { ...this.state.userContextValue } });
    }

    fetchChangeLogs(isInitialFetch) {
        const { cart } = this.state.orderContextValue;
        const { user } = this.state.userContextValue;

        const fetchChangeLogsIntervalInMilliseconds = window.APP_SETTINGS_FetchChangeLogsIntervalInSeconds * 1000;
        const fetchNextChangeLogs = () => this.fetchChangeLogsTimer = setTimeout(() => this.fetchChangeLogs(false), fetchChangeLogsIntervalInMilliseconds);

        return new Promise(resolve => {
            const messageBoxEl = document.getElementById(DocumentIdConstants.HomePageErrorCodeMessageBox);
            if (this.isPageVisible && messageBoxEl === null) {
                OrderApi.getChangeLogs(this.lastCartItemChangeLogId, this.lastUserSessionChangeLogId)
                    .then(result => {
                        const { cartItemChangeLogs, userSessionChangeLogs } = result;

                        if (cartItemChangeLogs.length !== 0) {
                            this.lastCartItemChangeLogId = Math.max(...cartItemChangeLogs.map(x => x.cartItemChangeLogId));
                            cart.applyChangeLogs(cartItemChangeLogs);
                        }

                        if (userSessionChangeLogs.length !== 0) {
                            this.lastUserSessionChangeLogId = Math.max(...userSessionChangeLogs.map(x => x.userSessionChangeLogId));
                            user.applyChangeLogs(userSessionChangeLogs, isInitialFetch);
                        }

                        resolve();
                        fetchNextChangeLogs();
                    })
                    .catch(() => {
                        resolve();
                        fetchNextChangeLogs();
                    });
            } else {
                resolve();
                fetchNextChangeLogs();
            }
        });
    }

    render() {
        const { isFetchingSettings, isFailedFetchingSettings } = this.state;
        if (isFailedFetchingSettings) return;

        return (
            isFetchingSettings
                ? <BlockUI blocking />
                : <BlockUIContext.Provider value={this.state.blockUIContextValue}>
                    <SettingsContext.Provider value={this.state.settingsContextValue}>
                        <MenuContext.Provider value={this.state.menuContextValue}>
                            <UserContext.Provider value={this.state.userContextValue}>
                                <OrderContext.Provider value={this.state.orderContextValue}>
                                    <BlockUI blocking={this.state.blockUIContextValue.blocking} />
                                    <ToastContainer
                                        bodyClassName="react-toastify-toast-body"
                                        position="top-center"
                                        autoClose={3000}
                                        closeOnClick
                                        pauseOnHover={false}
                                        pauseOnFocusLoss={false}
                                        draggable={false}
                                        theme="colored"
                                        transition={Slide}
                                    />
                                    <Router />
                                </OrderContext.Provider>
                            </UserContext.Provider>
                        </MenuContext.Provider>
                    </SettingsContext.Provider>
                </BlockUIContext.Provider>
        );
    }
}

export default WithRouter(App);