import { useEffect, useRef } from 'react';
import * as ObjectUtils from './objectUtils';

export function getDisplayName(WrappedComponent) {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export function usePrevious(value) {
    // Similar usage like ComponentDidUpdate in class component
    const ref = useRef();

    useEffect(() => {
        if (typeof value === 'object') {
            ref.current = ObjectUtils.deepCopy(value);
        } else {
            ref.current = value;
        }
    });

    return ref.current;
}

export const compose = (hocs) => innerComponent => hocs.reduceRight((component, hoc) => hoc(component), innerComponent);