export const dayOfWeekText = [
    { text: 'Sunday', value: 0 },
    { text: 'Monday', value: 1 },
    { text: 'Tuesday', value: 2 },
    { text: 'Wednesday', value: 3 },
    { text: 'Thursday', value: 4 },
    { text: 'Friday', value: 5 },
    { text: 'Saturday', value: 6 }
];

export function getDayOfWeekText(value) {
    const item = dayOfWeekText.find(x => x.value === value);
    return item ? item.text : '';
}