import React from 'react';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Overlay from 'components/Overlay';
import withOrderService from 'hocs/withOrderService';
import * as ComponentUtils from 'utils/component';
import * as EventUtils from 'utils/eventUtils';
import * as NumberUtils from 'utils/numberUtils';

const Footer = ({ confirmOrder, cart, settings, useEPayment }) => {
    const { finalTotal } = cart;
    const { localCurrencySymbol, currencyFormat } = settings;

    return (
        <div
            className="apps-general-box-shadow d-flex flex-column position-sticky bottom-0 px-3"
            style={{ borderTop: '1px solid var(--grey-300)' }}
        >
            <div className="d-flex align-items-center fs-3 fw-bold my-3">
                <span className="me-2">Final Total</span>

                <Overlay
                    trigger="click"
                    placement="top"
                    popoverText="The final total is an estimate and may differ slightly when you pay at the counter."
                >
                    <div
                        className="col-1 icon-icon-info text-muted"
                        onClick={e => EventUtils.cancelEventPropagation(e)}
                    />
                </Overlay>

                <span className="ms-auto">
                    {`${localCurrencySymbol} ${NumberUtils.formatNumber(finalTotal, currencyFormat)}`}
                </span>
            </div>

            <Button
                className="apps-general-shadow-pulse mb-3"
                primary
                onClick={confirmOrder}
            >
                <div className="d-flex align-items-center justify-content-center">
                    {useEPayment
                        ? <Icon name="fas fa-money-check" label="Select Payment Channel" showIconFirst={false} />
                        : 'Confirm Order'
                    }
                </div>
            </Button>
        </div>
    );
}

const hocs = [
    withOrderService
];

const FooterWithHocs = React.memo(ComponentUtils.compose(hocs)(Footer));

export { FooterWithHocs as InCartTabFooter };