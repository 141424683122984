import React, { Component } from 'react';
import { getDisplayName } from 'utils/component';
import * as EventUtils from 'utils/eventUtils';
import { deepCopy } from 'utils/objectUtils';

export default function withFormData(WrappedComponent) {
    class WithFormData extends Component {
        constructor(props) {
            super(props);

            this.state = {
                data: deepCopy(props.initialData),
                showSuccessMessageFunction: null,
                showErrorMessageFunction: null
            };

            this.autoFocusRef = React.createRef();
            this.handleChange = this.handleChange.bind(this);
            this.handleCancel = this.handleCancel.bind(this);
            this.handleSubmit = this.handleSubmit.bind(this);
            this.updateData = this.updateData.bind(this);
            this.setShowSuccessMessageFunction = this.setShowSuccessMessageFunction.bind(this);
            this.setShowErrorMessageFunction = this.setShowErrorMessageFunction.bind(this);
            this.showSuccessMessage = this.showSuccessMessage.bind(this);
            this.showErrorMessage = this.showErrorMessage.bind(this);
        }

        componentDidUpdate(prevProps) {
            if (this.props.initialData !== prevProps.initialData) {
                this.updateData();
            }
        }

        handleChange(newValue, dataIsModified = true) {
            this.setState({ data: Object.assign({}, this.state.data, newValue, { dataIsModified }) });
        }

        handleCancel() {
            this.props.onCancel(this.props.initialData, this.state.data);
        }

        handleSubmit(e) {
            EventUtils.cancelEventPropagation(e);
            this.props.onSubmit(this.props.initialData, this.state.data);
        }

        autoFocus() {
            this.autoFocusRef.current.instance.focus();
        }

        getData() {
            return {
                original: this.props.initialData,
                current: this.state.data
            };
        }

        updateData() {
            this.setState({ data: this.props.initialData });
        }

        setShowSuccessMessageFunction(showSuccessMessageFunction) {
            this.setState({ showSuccessMessageFunction });
        }

        setShowErrorMessageFunction(showErrorMessageFunction) {
            this.setState({ showErrorMessageFunction });
        }

        showSuccessMessage(text) {
            return this.state.showSuccessMessageFunction(text);
        }

        showErrorMessage(text) {
            return this.state.showErrorMessageFunction(text);
        }

        render() {
            const { initialData, onCancel, onSubmit, ...rest } = this.props;

            return (
                <WrappedComponent
                    initialData={initialData}
                    data={this.state.data}
                    handleChange={this.handleChange}
                    handleCancel={this.handleCancel}
                    handleSubmit={this.handleSubmit}
                    autoFocusRef={this.autoFocusRef}
                    setShowSuccessMessageFunction={this.setShowSuccessMessageFunction}
                    setShowErrorMessageFunction={this.setShowErrorMessageFunction}
                    {...rest}
                />
            );
        }
    }

    WithFormData.displayName = `WithFormData(${getDisplayName(WrappedComponent)})`;

    return WithFormData;
}