import * as PaymentChannelConstants from './paymentChannelConstant';

export const paymentChannelText = [
    { text: 'Visa / MasterCard', value: PaymentChannelConstants.RazerVisaOrMasterCard, imageSrc: '/img/payment-channel-credit-card-logo.png' },
    { text: 'Touch \'n Go', value: PaymentChannelConstants.RazerTouchAndGo, imageSrc: '/img/payment-channel-touch-and-go-logo.png' },
    { text: 'Boost', value: PaymentChannelConstants.RazerBoost, imageSrc: '/img/payment-channel-boost-logo.png' },
    { text: 'GrabPay', value: PaymentChannelConstants.RazerGrabPay, imageSrc: '/img/payment-channel-grab-pay-logo.png' },
    { text: 'Maybank QRPay', value: PaymentChannelConstants.RazerMaybankQRPay, imageSrc: '/img/payment-channel-maybank-qr-pay-logo.png' },
    { text: 'ShopeePay', value: PaymentChannelConstants.RazerShopeePay, imageSrc: '/img/payment-channel-shopee-pay-logo.png' },

    { text: 'Credit Card', value: PaymentChannelConstants.IPay88CreditCardMYR, imageSrc: '/img/payment-channel-credit-card-logo.png' },
    { text: 'Touch \'n Go', value: PaymentChannelConstants.IPay88TouchAndGo, imageSrc: '/img/payment-channel-touch-and-go-logo.png' },
    { text: 'Boost', value: PaymentChannelConstants.IPay88Boost, imageSrc: '/img/payment-channel-boost-logo.png' },
    { text: 'GrabPay', value: PaymentChannelConstants.IPay88GrabPay, imageSrc: '/img/payment-channel-grab-pay-logo.png' },
    { text: 'Maybank QRPay', value: PaymentChannelConstants.IPay88MaybankQRPay, imageSrc: '/img/payment-channel-maybank-qr-pay-logo.png' },
    { text: 'ShopeePay', value: PaymentChannelConstants.IPay88ShopeePay, imageSrc: '/img/payment-channel-shopee-pay-logo.png' },
    { text: 'PayPal', value: PaymentChannelConstants.IPay88PayPalMYR, imageSrc: '/img/payment-channel-pay-pal-logo.png' }
];

export function getPaymentChannel(value) {
    const item = paymentChannelText.find(x => x.value === value);
    return item ? item : null;
}

export function getPaymentChannelText(value) {
    const item = getPaymentChannel(value);
    return item ? item.text : '';
}