import React, { Component } from 'react';
import BottomSheetDialog from 'components/BottomSheetDialog';
import Button from 'components/Button';
import HintText from 'components/HintText';
import Image from 'components/Image';
import withBlockUI from 'hocs/withBlockUI';
import withOrderService from 'hocs/withOrderService';
import withRouter from 'hocs/withRouter';
import withUser from 'hocs/withUser';
import * as ComponentUtils from 'utils/component';
import * as RoutesUtils from 'utils/routesUtils';
import * as Toast from 'utils/toast';

class QuitOrderDialog extends Component {
    constructor(props) {
        super(props);

        this.quitOrder = this.quitOrder.bind(this);
    }

    quitOrder() {
        const { history, orderService } = this.props;

        this.props.blockUI();

        orderService.leaveUserSession()
            .then(() => {
                this.props.unblockUI();
                this.props.closeDialog();
                RoutesUtils.navigateToPage(history, 'Home');
            })
            .catch(errorMessage => {
                this.props.unblockUI();
                this.props.closeDialog();
                Toast.showErrorMessage(errorMessage);
            });
    }

    render() {
        const { closeDialog, shareCart, user } = this.props;

        return (
            <BottomSheetDialog
                isOpen
                closeDialog={closeDialog}
                footer={
                    <div className="d-flex flex-column">
                        <Button
                            className="w-75 mb-3"
                            style={{ margin: 'auto' }}
                            primary
                            onClick={this.quitOrder}
                        >
                            Quit Order
                        </Button>

                        <Button
                            className="w-75 mb-3"
                            style={{ margin: 'auto' }}
                            primary
                            outline
                            onClick={closeDialog}
                        >
                            Cancel
                        </Button>
                    </div>
                }
            >
                <div className="d-flex flex-column align-items-center px-4">
                    <Image
                        src="/img/stop-logo.png"
                        style={{ margin: 'auto' }}
                        width="100px"
                        height="100px"
                    />

                    <span className="text-center fs-3 fw-bold mb-3">
                        Quit Order?
                    </span>

                    <span className="mb-3">
                        No problem! You can always return by scanning the QR code.
                    </span>

                    {shareCart && user.getJoinedUserItemCount() === 1 &&
                        <HintText className="mb-3">
                            {`Just a heads-up: If you leave, all items in your cart will be removed since you're the only one here.`}
                        </HintText>
                    }
                </div>
            </BottomSheetDialog>
        );
    }
}

const hocs = [
    withBlockUI,
    withOrderService,
    withRouter,
    withUser
];

export default ComponentUtils.compose(hocs)(QuitOrderDialog);