import Home from 'pages/Home';
import Menu from 'pages/Menu';
import Redirect from 'pages/Redirect';

export const pagePaths = {
    Home: '/home',
    Menu: '/menu',
    Redirect: '/redirect/:eventType',
    QRCodeInvalid: '/qrCodeInvalid.html'
};

export const pageRoutes = [
    {
        name: 'Home',
        path: pagePaths.Home,
        component: Home
    },
    {
        name: 'Menu',
        path: pagePaths.Menu,
        component: Menu
    },
    {
        name: 'Redirect',
        path: pagePaths.Redirect,
        component: Redirect
    }
];