import * as Dialog from 'devextreme/ui/dialog';
import * as HtmlUtils from './htmlUtils';

export function showMessage(message, htmlFormat = false, buttonText = 'OK') {
    const dlg = Dialog.custom({
        showTitle: false,
        messageHtml: formatMessage(message, htmlFormat),
        buttons: [{
            text: buttonText,
            onClick: () => ({ ok: true })
        }]
    });

    return dlg.show();
}

export function showConfirmMessage(message, title = '', htmlFormat = false, yesButtonText = 'Yes', noButtonText = 'No', yesButtonType = 'default') {
    const dlg = Dialog.custom({
        showTitle: !!title,
        title,
        messageHtml: formatMessage(message, htmlFormat),
        buttons: [{
            text: yesButtonText,
            type: yesButtonType,
            onClick: () => ({ confirm: true })
        },
        {
            text: noButtonText,
            onClick: () => ({ confirm: false })
        }]
    });

    return dlg.show();
}

function formatMessage(message, htmlFormat) {
    const messageHtml = htmlFormat ? message : HtmlUtils.escapeHTML(message);
    return `<div style="max-width:480px">${messageHtml}</div>`;
}