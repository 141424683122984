import React from 'react';
import BackButton from 'components/BackButton';
import Dialog from 'components/Dialog';
import MenuItemImage from 'Menu/components/MenuItemImage';

const ImageDialog = ({ imageSrc, closeDialog }) => {
    return (
        <Dialog
            isOpen
            centered
            closeDialog={closeDialog}
        >
            <div className="position-relative">
                <MenuItemImage
                    style={{ objectFit: 'contain' }}
                    imageSrc={imageSrc}
                    width='100%'
                    height='100%'
                />

                <BackButton
                    className="position-absolute ion ion-md-close text-primary bg-white rounded-circle fs-1"
                    style={{
                        padding: '7px 10px 6px',
                        top: '12px',
                        right: '12px',
                    }}
                    onClick={closeDialog}
                />
            </div>
        </Dialog>
    );
}

export default ImageDialog;