export function generateColor(hex, lighten, percent) {
    // Generate derived color code based on the provided hex color code
    // - Check if hex is a valid color code
    const regex = /^#[0-9A-Fa-f]{6}$/;
    if (!regex.test(hex)) return hex;

    // - Remove '#' character from hex
    hex = hex.replace('#', '');

    // - Convert hex color to Rgb class
    const rgbInput = new Rgb(
        parseInt(hex.slice(0, 2), 16),
        parseInt(hex.slice(2, 4), 16),
        parseInt(hex.slice(4, 6), 16)
    );

    // - Create target Rgb class
    const targetRgb = lighten ? Rgb.createWhite() : Rgb.createBlack();

    // - Get desired result for target Rgb class
    while (targetRgb.isCloseToColor(lighten) && percent >= 0) {
        const result = rgbInput.calculateDerivedValue(percent, lighten);

        targetRgb.red = result.red;
        targetRgb.green = result.green;
        targetRgb.blue = result.blue;

        percent--;
    }

    return `#${((targetRgb.red << 16) | (targetRgb.green << 8) | targetRgb.blue).toString(16).padStart(6, '0')}`;
}

class Rgb {
    constructor(red, green, blue) {
        this.red = red;
        this.green = green;
        this.blue = blue;

        this.isCloseToColor = this.isCloseToColor.bind(this);
        this.calculateDerivedValue = this.calculateDerivedValue.bind(this);
    }

    static createWhite() {
        return new Rgb(255, 255, 255);
    }

    static createBlack() {
        return new Rgb(0, 0, 0);
    }

    isCloseToColor(toLighter) {
        const threshold = 20;

        if (toLighter) return this._isCloseToWhite(threshold);
        else return this._isCloseToBlack(threshold);
    }

    calculateDerivedValue(percent, toLighter) {
        // Calculate derived rgb value 
        const factor = percent / 100;
        const adjustment = toLighter ? 255 * factor : -255 * factor;

        return {
            red: this._clamp(this.red + adjustment),
            green: this._clamp(this.green + adjustment),
            blue: this._clamp(this.blue + adjustment)
        };
    }

    _isCloseToWhite(threshold) {
        return (
            (255 - this.red <= threshold) &&
            (255 - this.green <= threshold) &&
            (255 - this.blue <= threshold)
        );
    }

    _isCloseToBlack(threshold) {
        return (
            (0 + this.red <= threshold) &&
            (0 + this.green <= threshold) &&
            (0 + this.blue <= threshold)
        );
    }

    _clamp(value) {
        // Clamp a value to the range of [0, 255]
        return Math.min(255, Math.max(0, value));
    }
}