/** @jsxImportSource @emotion/react */
/* eslint-disable react/no-unknown-property */
import { css } from '@emotion/react';

const BlockUI = ({ blocking }) => {
    const containerCss = css`
        position: fixed;
        z-index: 10000;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        cursor: wait;
        overflow: hidden;
        animation-name: ${blocking ? 'fadeIn' : 'fadeOut'};
        animation-duration: 0.5s;
        height: ${blocking ? '100%' : '0%'};
        opacity: ${blocking ? 1 : 0};
        
        @keyframes fadeIn {
            0% {opacity: 0; }
            100% {opacity: 1; }
        }
        
        @keyframes fadeOut {
            0% {opacity: 1; height: 100%; } 
            99.9% {opacity: 0; height: 100%; }
            100% {opacity: 0; height: 0; }
        }
    `;

    const overlayStyle = {
        width: '100%',
        height: '100%',
        opacity: 0.25
    };

    const spinContainerStyle = {
        position: 'absolute',
        top: '50%',
        left: 0,
        right: 0,
        textAlign: 'center',
        transform: 'translateY(-50%)',
        zIndex: 10001
    };

    const spinStyle = {
        margin: '100px auto',
        width: 40,
        height: 40,
        position: 'relative'
    };

    const getSpinDotCss = (index) => {
        const rotateDegree = index * 30;
        const animationDelay = -1.1 + index * 0.1;

        return css`
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            -webkit-transform: rotate(${rotateDegree}deg);
            -ms-transform: rotate(${rotateDegree}deg);
            transform: rotate(${rotateDegree}deg);
            
            &:before {
                content: '';
                display: block;
                margin: 0 auto;
                width: 15%;
                height: 15%;
                border-radius: 100%;
                background-color: currentColor;
                -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
                animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
                -webkit-animation-delay: ${animationDelay}s;
                animation-delay: ${animationDelay}s;
            }
            
            @keyframes sk-circleBounceDelay {
                0%, 80%, 100% {-webkit-transform: scale(0); transform: scale(0);}    
                40% {-webkit-transform: scale(1); transform: scale(1);}
            }`;
    };

    return (
        <div css={containerCss} >
            <div className="apps-component-block-ui-overlay" style={overlayStyle} />
            <div style={spinContainerStyle}>
                <div style={spinStyle}>
                    <div className="text-primary" css={getSpinDotCss(0)} />
                    <div className="text-primary" css={getSpinDotCss(1)} />
                    <div className="text-primary" css={getSpinDotCss(2)} />
                    <div className="text-primary" css={getSpinDotCss(3)} />
                    <div className="text-primary" css={getSpinDotCss(4)} />
                    <div className="text-primary" css={getSpinDotCss(5)} />
                    <div className="text-primary" css={getSpinDotCss(6)} />
                    <div className="text-primary" css={getSpinDotCss(7)} />
                    <div className="text-primary" css={getSpinDotCss(8)} />
                    <div className="text-primary" css={getSpinDotCss(9)} />
                    <div className="text-primary" css={getSpinDotCss(10)} />
                    <div className="text-primary" css={getSpinDotCss(11)} />
                </div>
            </div>
        </div>
    );
};

export default BlockUI;