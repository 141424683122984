import * as Api from 'utils/api';
import { buildApiUrl } from 'utils/session';

const basePath = 'application';

export function getSettings(qrCode) {
    const url = buildApiUrl(`${basePath}/getsettings`, { qrCode });
    return Api.getData(url);
}

export function validateQRCode(qrCode) {
    const url = buildApiUrl(`${basePath}/validateqrcode`, { qrCode });
    return Api.postData(url).then(response => response.json());
}

export function hasChangeTable(qrCode, tableSessionId) {
    const url = buildApiUrl(`${basePath}/haschangetable`);
    return Api.postData(url, { qrCode, tableSessionId }).then(response => response.json());
}