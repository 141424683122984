import React from 'react';
import classNames from 'classnames';

const HintText = ({ className, children }) => {
    const classes = classNames('text-muted fs-6', className);

    return (
        <span className={classes}>
            {children}
        </span>
    );
};

export default HintText;