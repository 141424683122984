import React, { Component } from 'react';
import { LoadPanel } from 'devextreme-react/load-panel';
import withOrderService from 'hocs/withOrderService';
import withRouter from 'hocs/withRouter';
import * as ComponentUtils from 'utils/component';
import { pagePaths } from 'utils/routes';
import * as RoutesUtils from 'utils/routesUtils';
import * as Session from 'utils/session';
import * as Toast from 'utils/toast';
import * as EventTypes from '../data/eventType';

class Page extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true
        };
    }

    componentDidMount() {
        const { params, location, orderService } = this.props;
        const urlParams = new URLSearchParams(location.search);
        const urlPaymentStatus = urlParams.get('paymentStatus');

        if (params.eventType === EventTypes.FromPayment && urlPaymentStatus) {
            // Redirect from payment action
            const routeOptions = {
                paymentStatus: urlPaymentStatus
            };

            orderService.startOrder(Session.getShareCart(), false, routeOptions)
                .then(() => {
                    this.setState({ isLoading: false });
                })
                .catch(errorMessage => {
                    this.setState({ isLoading: false });
                    Toast.showErrorMessage(errorMessage);
                });
        } else {
            // Redirect to home page (default)
             RoutesUtils.redirectToPage(pagePaths.Home);
        }
    }

    render() {
        const { isLoading } = this.state;

        return (
            <LoadPanel
                visible={isLoading}
                shading={false}
                message="Redirecting ..."
            />
        );
    }
}

const hocs = [
    withOrderService,
    withRouter
];

export default ComponentUtils.compose(hocs)(Page);