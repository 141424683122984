import Guid from 'devextreme/core/guid';
import BigNumber from 'bignumber.js';
import * as RoundingMethodConstants from 'data/roundingMethodConstant';
import * as ServiceTypeConstants from 'data/serviceTypeConstant';
import * as NumberUtils from 'utils/numberUtils';

export default class Cart {
    constructor(cart, onCartUpdated, settings, orderServiceType) {
        this.items = [];
        this.onCartUpdated = onCartUpdated;
        this.orderServiceType = orderServiceType;
        this.settings = {};

        if (cart) {
            // Apply sorting for CartItems children
            for (const item of cart.items) {
                this.sortCartItemChildren(item);
            }

            this.items.push(...cart.items);
        }

        if (settings) {
            // Calculate Cart derived values after fetching settings
            this.settings = settings;
            this._recalculateCartItemsDerivedTaxValues(this.items);
            this._recalculateCartDerivedValue();
        }
    }

    hasAnyItems() {
        // Return true if has any items
        return this.items.length > 0;
    }

    findCartItem(cartItemId) {
        // Find CartItem using CartItemId
        return this.items.find(x => x.cartItemId === cartItemId);
    }

    addCartItem(item) {
        // Add CartItem
        this.sortCartItemChildren(item);
        this.items = [...this.items, item];
        this._recalculateCartItemsDerivedTaxValues(this.items);
        this._recalculateCartDerivedValue();
        this.onCartUpdated();
    }

    updateCartItem(item) {
        // Update CartItem
        const currentItem = this.items.find(x => x.cartItemId === item.cartItemId);
        if (currentItem) {
            this.sortCartItemChildren(item);
            const index = this.items.indexOf(currentItem);
            this.items[index] = item;
            this._recalculateCartItemsDerivedTaxValues(this.items);
            this._recalculateCartDerivedValue();
            this.onCartUpdated();
        }
    }

    removeCartItem(cartItemId) {
        // Remove CartItem
        this.items = this.items.filter(x => x.cartItemId !== cartItemId);
        this._recalculateCartItemsDerivedTaxValues(this.items);
        this._recalculateCartDerivedValue();
        this.onCartUpdated();
    }

    clearCart() {
        // Clear all CartItems
        this.items = [];
        this._recalculateCartDerivedValue();
        this.onCartUpdated();
    }

    applyChangeLogs(changeLogs) {
        let items = [...this.items];

        for (const changeLog of changeLogs) {
            if (changeLog.isDelete) {
                // Delete
                items = items.filter(x => x.cartItemId !== changeLog.cartItemId);
            } else {
                const itemIndex = items.findIndex(x => x.cartItemId === changeLog.cartItemId);
                this.sortCartItemChildren(changeLog.cartItem);

                if (itemIndex === -1) {
                    // Create
                    items = [...items, changeLog.cartItem];
                } else {
                    // Update
                    items.splice(itemIndex, 1, changeLog.cartItem);
                }
            }
        }

        this.items = items;
        this._recalculateCartItemsDerivedTaxValues(this.items);
        this._recalculateCartDerivedValue();
        this.onCartUpdated();
    }

    calculateMenuItemTotalQuantity(menuItemGuid) {
        // Calculate CartItem total quantity for a MenuItem
        return this.items.reduce((total, item) => {
            if (item.menuItemGuid === menuItemGuid) {
                total += item.quantity;
            }

            return total;
        }, 0);
    }

    calculateFinalTotal(netTotal) {
        // Calculate FinalTotal via rounding
        const { roundingOption } = this.settings;

        if (roundingOption === RoundingMethodConstants.NoRounding) return netTotal;
        else if (roundingOption === RoundingMethodConstants.RoundToNearest5Cent) return Math.round(netTotal * 20) / 20;
        else return Math.floor(netTotal * 10) / 10;
    }

    recalculateCartItemDerivedValues(item) {
        // Calculate SubTotal for CartItem
        const { currencyFormat } = this.settings;

        if (!item.menuSetMealGuid) {
            let modifierItemSubTotals = 0;

            for (const modifierItem of item.modifierItems) {
                modifierItem.subTotal = NumberUtils.roundNumberFromFormat(modifierItem.surcharge * modifierItem.quantity, currencyFormat);
                modifierItemSubTotals = NumberUtils.roundNumberFromFormat(modifierItemSubTotals + modifierItem.subTotal, currencyFormat);
            }

            item.subTotal = NumberUtils.roundNumberFromFormat(item.quantity * (modifierItemSubTotals + item.price), currencyFormat);
        } else {
            let setMealItemSubTotals = 0;

            for (const setMealItem of item.setMealItems) {
                this.recalculateCartSetMealItemDerivedValues(setMealItem);
                setMealItemSubTotals += setMealItem.subTotal;
            }

            item.subTotal = item.quantity * setMealItemSubTotals;
        }
    }

    recalculateCartSetMealItemDerivedValues(cartSetMealItem) {
        // Calculate SubTotal for CartSetMealItem
        const { currencyFormat } = this.settings;

        let modifierItemSubTotals = 0;

        for (const modifierItem of cartSetMealItem.modifierItems) {
            modifierItem.subTotal = NumberUtils.roundNumberFromFormat(modifierItem.surcharge * modifierItem.quantity, currencyFormat);
            modifierItemSubTotals = NumberUtils.roundNumberFromFormat(modifierItemSubTotals + modifierItem.subTotal, currencyFormat);
        }

        cartSetMealItem.subTotal = NumberUtils.roundNumberFromFormat((cartSetMealItem.price + modifierItemSubTotals) * cartSetMealItem.quantity, currencyFormat);
    }

    buildCartItem(menuItem) {
        // Build CartItem (CartItemViewModel) from MenuItem (MenuItemViewModel)
        const isSetMeal = !!menuItem.setMeal;

        const result = {
            cartItemId: new Guid().toString(),
            menuItemGuid: menuItem.menuItemGuid,
            menuSetMealGuid: isSetMeal ? menuItem.setMeal.menuSetMealGuid : null,
            name: menuItem.name,
            description: menuItem.description,
            itemCode: menuItem.itemCode,
            displayCode: menuItem.displayCode,
            taxCode: menuItem.taxCode,
            taxRate: menuItem.taxRate,
            price: menuItem.price,
            image: menuItem.image,
            serviceType: this.orderServiceType,
            quantity: 1,
            remark: null,
            modifierItems: [],
            setMealItems: []
        };

        // - Assign default Modifier / SetMealItem 
        if (isSetMeal) {
            for (const category of menuItem.setMeal.categories) {
                const setMealItem = this._getDefaultSetMealItem(category);

                if (setMealItem) {
                    result.setMealItems.push(this.buildCartSetMealItem(category, setMealItem, category.minQty));
                }
            }
        } else {
            for (const modifierCategory of menuItem.modifierCategories) {
                const modifierItem = modifierCategory.items.find(x => x.isDefault && !x.isOutOfStock);

                if (modifierItem) {
                    const cartItemModifierItem = this.buildCartItemModifierItem(modifierCategory, modifierItem);
                    result.modifierItems.push(cartItemModifierItem);
                }
            }
        }

        this.recalculateCartItemDerivedValues(result);

        return result;
    }

    buildCartItemModifierItem(modifierCategory, modifierItem) {
        // Build CartItemModifierItem
        return {
            cartItemModifierItemId: new Guid().toString(),
            menuModifierItemGuid: modifierItem.menuModifierItemGuid,
            menuModifierCategoryGuid: modifierCategory.menuModifierCategoryGuid,
            description: modifierItem.description,
            image: modifierItem.image,
            itemCode: modifierItem.itemCode,
            surcharge: modifierItem.surcharge,
            subTotal: modifierItem.surcharge,
            quantity: 1,
            menuModifierCategorySeq: modifierCategory.seq,
            menuModifierItemSeq: modifierItem.seq,
        };
    }

    buildCartSetMealItem(setMealCategory, setMealItem, quantity) {
        // Build CartSetMealItem
        // - Set default quantity as 1 if is null / undefined
        quantity = quantity ?? 1;

        const result = {
            cartSetMealItemId: new Guid().toString(),
            menuSetMealCategoryGuid: setMealCategory.menuSetMealCategoryGuid,
            menuSetMealItemGuid: setMealItem.menuSetMealItemGuid,
            name: setMealItem.name,
            itemCode: setMealItem.itemCode,
            taxCode: setMealItem.taxCode,
            taxRate: setMealItem.taxRate,
            price: setMealItem.price,
            image: setMealItem.image,
            quantity,
            menuSetMealCategorySeq: setMealCategory.seq,
            menuSetMealItemSeq: setMealItem.seq,
            modifierItems: []
        };

        // - Assign default Modifier 
        for (const modifierCategory of setMealItem.modifierCategories) {
            const modifierItem = modifierCategory.items.find(x => x.isDefault && !x.isOutOfStock);

            if (modifierItem) {
                const cartSetMealItemModifierItem = this.buildCartSetMealItemModifierItem(result, modifierCategory, modifierItem);
                result.modifierItems.push(cartSetMealItemModifierItem);
            }
        }

        return result;
    }

    buildCartSetMealItemModifierItem(cartSetMealItem, modifierCategory, modifierItem) {
        // Build CartSetMealItemModifierItem
        return {
            cartSetMealItemModifierItemId: new Guid().toString(),
            cartSetMealItemId: cartSetMealItem.cartSetMealItemId,
            menuModifierItemGuid: modifierItem.menuModifierItemGuid,
            menuModifierCategoryGuid: modifierCategory.menuModifierCategoryGuid,
            description: modifierItem.description,
            image: modifierItem.image,
            itemCode: modifierItem.itemCode,
            surcharge: modifierItem.surcharge,
            subTotal: modifierItem.surcharge,
            quantity: 1,
            menuModifierCategorySeq: modifierCategory.seq,
            menuModifierItemSeq: modifierItem.seq
        };
    }

    sortCartItemChildren(item) {
        // Sort CartItem children
        if (item.menuSetMealGuid) {
            this._sortSetMealItem(item.setMealItems);

            for (const setMealItem of item.setMealItems) {
                this.sortModifierItems(setMealItem.modifierItems);
            }
        } else {
            this.sortModifierItems(item.modifierItems);
        }
    }

    sortModifierItems(modifierItems) {
        this._sort(modifierItems, [x => x.menuModifierCategorySeq, x => x.menuModifierItemSeq]);
    }

    _getDefaultSetMealItem(setMealCategory) {
        // Return default SetMealItem
        // - Check has any default SetMealItem
        const defaultSetMealItem = setMealCategory.items.find(x => x.isDefault && !x.isOutOfStock);
        if (defaultSetMealItem && hasDefaultItemForModifierCategory(defaultSetMealItem)) {
            return defaultSetMealItem;
        }

        // - Check category has minQty & only one SetMealItem option available
        const isItemRequired = !!setMealCategory.minQty;
        const hasOneItemOptionOnly = setMealCategory.items.length === 1;
        const firstItemOption = setMealCategory.items[0];

        if (isItemRequired &&
            hasOneItemOptionOnly &&
            !firstItemOption.isOutOfStock &&
            hasDefaultItemForModifierCategory(firstItemOption)) {
            return firstItemOption;
        }

        return null;

        function hasDefaultItemForModifierCategory(setMealItem) {
            let isValid = true;

            for (const modifierCategory of setMealItem.modifierCategories) {
                const isItemRequired = !!modifierCategory.minQty;

                if (isItemRequired && (modifierCategory.minQty > 1 || !modifierCategory.items.some(x => x.isDefault && !x.isOutOfStock))) {
                    isValid = false;
                    break;
                }
            }

            return isValid;
        }
    }

    _initBigNumber(value) {
        // Handle null as zero (aligned with javascript behaviour)
        // BigNumber.js default constructor can't handle null
        if (value === null) value = 0;
        return new BigNumber(value);
    }

    _roundBNToNumber(value) {
        // Convert BigNumber to number data type
        const { currencyDecimal } = this.settings;
        return value.dp(currencyDecimal).toNumber();
    }

    _recalculateCartItemsDerivedTaxValues(items) {
        // Apply Whole Document Rounding
        const { currencyFormat } = this.settings;

        const round = (value) => NumberUtils.roundNumberFromFormat(value, currencyFormat);

        let lastServiceChargeValue = 0;
        let lastTaxValue = 0;

        for (const item of items) {
            if (!item.menuSetMealGuid) {
                const values = this._buildCartItemDerivedTaxValues(item.serviceType, item.taxCode, item.taxRate, item.subTotal, lastServiceChargeValue, lastTaxValue);

                item.serviceCharge = values.serviceCharge;
                item.tax = values.tax;
                item.subTotalExTax = values.subTotalExTax;
                item.subTotalIncTax = values.subTotalIncTax;
                lastServiceChargeValue = values.lastServiceChargeValue;
                lastTaxValue = values.lastTaxValue;

            } else {
                let serviceCharge = 0;
                let tax = 0;
                let subTotalIncTax = 0;
                let subTotalExTax = 0;

                for (const setMealItem of item.setMealItems) {
                    const values = this._buildCartItemDerivedTaxValues(item.serviceType, setMealItem.taxCode, setMealItem.taxRate, round(setMealItem.subTotal * item.quantity), lastServiceChargeValue, lastTaxValue);

                    serviceCharge = round(serviceCharge + values.serviceCharge);
                    tax = round(tax + values.tax);
                    subTotalIncTax = round(subTotalIncTax + values.subTotalIncTax);
                    subTotalExTax = round(subTotalExTax + values.subTotalExTax);
                    lastServiceChargeValue = values.lastServiceChargeValue;
                    lastTaxValue = values.lastTaxValue;
                }

                item.serviceCharge = serviceCharge;
                item.tax = tax;
                item.subTotalIncTax = subTotalIncTax;
                item.subTotalExTax = subTotalExTax;
            }
        }
    }

    _recalculateCartDerivedValue() {
        const { currencyFormat } = this.settings;
        const items = this.items;

        const round = (value) => NumberUtils.roundNumberFromFormat(value, currencyFormat);
        const sum = (items, selector, initialValue = 0) => items.reduce((total, item) => total + selector(item), initialValue);

        const totalQuantity = sum(items, item => item.quantity);
        const serviceCharge = round(sum(items, item => item.serviceCharge));
        const tax = round(sum(items, item => item.tax));
        const totalAmountIncTax = round(sum(items, item => item.subTotalIncTax));
        const totalAmountExTax = round(sum(items, item => item.subTotalExTax));
        const netTotal = round(totalAmountIncTax + serviceCharge);
        const finalTotal = round(this.calculateFinalTotal(netTotal));
        const roundingAdjustment = round(finalTotal - netTotal);

        this.totalQuantity = totalQuantity;
        this.serviceCharge = serviceCharge;
        this.tax = tax;
        this.totalAmountIncTax = totalAmountIncTax;
        this.totalAmountExTax = totalAmountExTax;
        this.netTotal = netTotal;
        this.finalTotal = finalTotal;
        this.roundingAdjustment = roundingAdjustment;
    }

    _buildCartItemDerivedTaxValues(serviceType, taxCode, taxRate, subTotal, lastServiceChargeValue, lastTaxValue) {
        // Build CartItem derived tax values
        const { enableServiceCharge, enableTax, serviceChargeRate } = this.settings;
        const isInclusive = this.settings.serviceChargeAndTaxInclusive;

        // Init values
        const shouldCalculateServiceCharge = enableServiceCharge && serviceType !== ServiceTypeConstants.TakeAway && serviceChargeRate > 0;
        const shouldCalculateTax = enableTax && taxCode !== null && taxRate !== null && taxRate > 0;
        const totalPercentage = 100 + (shouldCalculateServiceCharge ? serviceChargeRate : 0) + (shouldCalculateTax ? taxRate : 0);

        let serviceChargeRounded = 0;
        let taxRounded = 0;

        subTotal = this._initBigNumber(subTotal);

        // Calculate service charge
        if (shouldCalculateServiceCharge) {
            const serviceCharge = isInclusive
                ? subTotal.div(totalPercentage).times(serviceChargeRate).plus(lastServiceChargeValue)
                : subTotal.div(100).times(serviceChargeRate).plus(lastServiceChargeValue);

            serviceChargeRounded = this._roundBNToNumber(serviceCharge);
            lastServiceChargeValue = serviceCharge.minus(serviceChargeRounded).toNumber();
        }

        // Calculate tax
        if (shouldCalculateTax) {
            const tax = isInclusive
                ? subTotal.div(totalPercentage).times(taxRate).plus(lastTaxValue)
                : subTotal.div(100).times(taxRate).plus(lastTaxValue);

            taxRounded = this._roundBNToNumber(tax)
            lastTaxValue = tax.minus(taxRounded).toNumber();
        }

        // Calculate subtotal
        const subTotalIncTax = isInclusive
            ? this._roundBNToNumber(subTotal.minus(serviceChargeRounded))
            : this._roundBNToNumber(subTotal.plus(taxRounded));

        const subTotalExTax = isInclusive
            ? this._roundBNToNumber(subTotal.minus(serviceChargeRounded).minus(taxRounded))
            : this._roundBNToNumber(subTotal);

        return {
            serviceCharge: serviceChargeRounded,
            tax: taxRounded,
            subTotalIncTax,
            subTotalExTax,
            lastServiceChargeValue,
            lastTaxValue
        };
    }

    _sortSetMealItem(setMealItems) {
        this._sort(setMealItems, [x => x.menuSetMealCategorySeq, x => x.menuSetMealItemSeq]);
    }

    _sort(items, selectors) {
        items.sort((a, b) => {
            for (const selector of selectors) {
                const aValue = selector(a);
                const bValue = selector(b);

                if (aValue < bValue) return -1;
                if (aValue > bValue) return 1;
            }

            return 0;
        })
    }
}