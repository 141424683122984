const Radio = ({ value, groupValue, checked, disabled, handleChange }) =>
    <input
        id={value}
        name={groupValue}
        className="form-check-input"
        type="radio"
        checked={checked}
        disabled={disabled}
        onChange={handleChange}
    />;

export default Radio;