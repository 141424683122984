import React from 'react';
import EmptyLine from 'components/EmptyLine';
import withOrderService from 'hocs/withOrderService';
import withUser from 'hocs/withUser';
import * as ComponentUtils from 'utils/component';
import * as NumberUtils from 'utils/numberUtils';
import CartItemPanel from './CartItemPanel';
import ContentWrapper from './ContentWrapper';
import AlertBanner from '../Shared/AlertBanner';
import EmptyItem from '../Shared/EmptyItem';

const InCartTab = ({ closeOrderDialog, user, cart, settings, shareCart }) => {
    const { userId } = user;
    const { localCurrencySymbol, currencyFormat, serviceChargeRate } = settings;
    const { totalQuantity, serviceCharge, tax, totalAmountExTax, roundingAdjustment, finalTotal } = cart;

    function renderCartItems() {
        const contents = [];

        if (shareCart) {
            // Group CartItems based on userId
            const userCartItemGroups = user.items.reduce((arr, item) => {
                const userCartItems = cart.items.filter(x => x.userId === item.userId);

                if (userCartItems.length !== 0) {
                    arr.push({
                        userId: item.userId,
                        items: userCartItems,
                        seq: item.seq
                    });
                }

                return arr;
            }, []);

            // Apply sorting to ensure first UserCartItemGroup belongs to current user
            const sortedUserCartItemGroups = userCartItemGroups.sort((a, b) => {
                if (a.userId === userId) {
                    return -1;
                } else if (b.userId === userId) {
                    return 1;
                } else {
                    return 0;
                }
            });

            for (const userCartItemGroup of sortedUserCartItemGroups) {
                contents.push(
                    <ContentWrapper
                        key={userCartItemGroup.userId}
                        iconName="icon-icon-guest2"
                        iconLabel={`Guest ${userCartItemGroup.seq} ${userCartItemGroup.userId === userId ? '(You)' : ''}`}
                    >
                        {userCartItemGroup.items.map((item, index) => renderLine(item, index === userCartItemGroup.items.length - 1))}
                    </ContentWrapper>
                );
            }
        } else {
            contents.push(
                <ContentWrapper
                    key={userId}
                    iconName="fas fa-shopping-basket"
                    iconLabel="Cart"
                >
                    {cart.items.map((item, index) => renderLine(item, index === cart.items.length - 1))}
                </ContentWrapper>
            );
        }

        return contents;

        function renderLine(item, isLastItem) {
            return (
                <React.Fragment key={item.cartItemId}>
                    <CartItemPanel cartItem={item} />
                    {!isLastItem && <EmptyLine className="mt-3" />}
                </React.Fragment>
            );
        }
    }

    function renderSummary() {
        return (
            <ContentWrapper iconName="fas fa-cart-plus" iconLabel="Summary">
                {renderLine('Total Quantity', totalQuantity, false, false, true)}
                {renderLine('Subtotal (ex)', totalAmountExTax)}
                {tax !== 0 && renderLine('Tax', tax)}
                {serviceCharge !== 0 && renderLine(`Service Charge (${serviceChargeRate}%)`, serviceCharge)}
                {renderLine('Rounding Adjustment', roundingAdjustment)}
                {renderLine('Final Total', finalTotal, true, true, true)}
            </ContentWrapper>
        );

        function renderLine(title, value, isCurrency = true, isEmphasize = false, extraMarginTop = false) {
            const lineClasses = `d-flex ${isEmphasize ? 'fw-bold' : 'fs-5'} ${extraMarginTop ? 'mt-3' : 'mt-1'} `;
            const lineTitleClasses = `col-8 ${isEmphasize ? '' : 'text-muted'}`;

            return (
                <div className={lineClasses}>
                    <span className={lineTitleClasses}>{title}</span>
                    <span className="col-4 text-end">
                        {isCurrency ? `${localCurrencySymbol} ${NumberUtils.formatNumber(value, currencyFormat)}` : value}
                    </span>
                </div>
            );
        }
    }

    return (
        cart.hasAnyItems()
            ? <>
                <AlertBanner>Check items before placing your order.</AlertBanner>

                {renderCartItems()}
                {renderSummary()}
            </>
            : <EmptyItem isOrdered={false} closeDialog={closeOrderDialog} />
    );
}

const hocs = [
    withOrderService,
    withUser
];

export default React.memo(ComponentUtils.compose(hocs)(InCartTab));