import React from 'react';

const AlertBanner = ({ children }) => {
    return (
        <div
            className="d-flex align-items-center justify-content-center p-3"
            style={{ background: '#fff6e2' }}
        >
            <span className="icon-icon-info2 me-2" style={{ color: '#ff0707' }} />
            <span className="fs-6">{children}</span>
        </div>
    );
}

export default AlertBanner;