import React, { useState, useRef } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import BackButton from 'components/BackButton';
import withBlockUI from 'hocs/withBlockUI';
import withOrderService from 'hocs/withOrderService';
import withRouter from 'hocs/withRouter';
import withUser from 'hocs/withUser';
import withCartModeFunction from 'ui/CartMode/hocs/withCartMode';
import withQuitOrder from 'ui/QuitOrder/hocs/withQuitOrder';
import * as ComponentUtils from 'utils/component';
import * as ZIndexUtils from 'utils/zIndexUtils';
import AboutDialog from './AboutDialog';

const Sidenav = ({ shareCart, user, closeSidenav, readCartMode, openQuitOrderDialog }) => {
    const [showAboutDialog, setShowAboutDialog] = useState(false);

    const sideNavRef = useRef();

    const sidenavItems = [
        {
            icon: 'fas fa-shopping-cart',
            text: 'Cart Mode',
            onClick: () => readCartMode()
        },
        {
            icon: 'fas fa-info',
            text: 'About',
            onClick: () => setShowAboutDialog(true)
        },
        {
            icon: 'fas fa-door-open',
            text: 'Quit Order',
            onClick: () => openQuitOrderDialog()
        }
    ];

    function onSidenavOpened() {
        ZIndexUtils.updateZIndexWithClassName('.offcanvas-backdrop');
        ZIndexUtils.updateZIndexWithComponent(sideNavRef.current.dialog);
    }

    return (
        <>
            <Offcanvas
                show
                placement="start"
                onHide={closeSidenav}
                onShow={onSidenavOpened}
                ref={sideNavRef}
            >
                <Offcanvas.Header
                    className="align-items-start"
                    style={{ background: 'var(--primary)', height: '15vh' }}
                >
                    <span className="text-white fw-bold">
                        {`Welcome, Guest ${shareCart ? user.getCurrentUserItemSeq() : ''}`}
                    </span>

                    <BackButton className="d-none" onClick={closeSidenav} />
                </Offcanvas.Header>

                <Offcanvas.Body className="d-flex flex-column">
                    {sidenavItems.map(item =>
                        <div
                            key={item.text}
                            className="d-flex align-items-center mb-4"
                            onClick={item.onClick}
                        >
                            <span className={`col-1 ${item.icon} text-primary text-center me-3`} />
                            <span>{item.text}</span>
                        </div>
                    )}
                </Offcanvas.Body>
            </Offcanvas>

            {showAboutDialog &&
                <AboutDialog
                    closeDialog={() => setShowAboutDialog(false)}
                />
            }
        </>

    );
}

const hocs = [
    withBlockUI,
    withOrderService,
    withRouter,
    withUser,
    withCartModeFunction,
    withQuitOrder
];

export default React.memo(ComponentUtils.compose(hocs)(Sidenav));