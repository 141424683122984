import * as CartModeConstants from './cartModeConstant';

export const cartModes = [
    {
        title: 'Join Existing Cart',
        description: 'You can edit the existing cart together with your tablemate.',
        imageSrc: 'img/share-cart-logo.png',
        value: CartModeConstants.ShareCart
    },
    {
        title: 'Individual Cart',
        description: 'You can edit your own cart exclusively.',
        imageSrc: 'img/individual-cart-logo.png',
        value: CartModeConstants.IndividualCart
    },
];

export function getCartMode(value) {
    const item = cartModes.find(x => x.value === value);
    return item;
}