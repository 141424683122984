import React from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';

function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        return (
            <Component
                {...props}
                location={useLocation()}
                history={useHistory()}
                params={useParams()}
            />
        );
    }

    return ComponentWithRouterProp;
}

export default withRouter;