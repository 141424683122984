import React, { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import AccordionButton from 'components/AccordionButton';
import Checkbox from 'components/Checkbox';
import Image from 'components/Image';
import Radio from 'components/Radio';
import RippleWrapper from 'components/RippleWrapper';
import withOrderService from 'hocs/withOrderService';
import MenuItemPrice from 'Menu/components/MenuItemPrice';
import * as ComponentUtils from 'utils/component';
import { usePrevious } from 'utils/component';
import * as EventUtils from 'utils/eventUtils';
import * as Toast from 'utils/toast';
import ValidatorElement from './ValidatorElement';
import * as CustomizeBuilder from '../../utils/customizeBuilder';

const withAnimationClass = 'with-animation';

const ModifierCategoryPanel = ({
    modifierCategory,
    cartItemModifierItems,
    cartSetMealItem = null,
    activeEventKey,
    accordionButtonRef,
    toggleAccordion,
    openImageDialog,
    handleModifierItemChange,
    cart }) => {
    const { menuModifierCategoryGuid, name, minQty, maxQty } = modifierCategory;

    const isRadioInputType = minQty && maxQty && minQty === 1 && maxQty === 1;
    const allowedQtyText = CustomizeBuilder.getAllowedQtyText(minQty, maxQty);

    const cartItemModifierCategoryModifierItems = cartItemModifierItems.filter(x => x.menuModifierCategoryGuid === menuModifierCategoryGuid);
    const totalQty = cartItemModifierCategoryModifierItems.reduce((total, item) => total + item.quantity, 0);

    const isRenderedByMenuItem = cartSetMealItem === null;

    const isAccordionExpanded = activeEventKey !== null;

    const [validationDtl, setValidationDtl] = useState(null);
    const prevCartItemModifierCategoryModifierItems = usePrevious(cartItemModifierCategoryModifierItems);

    useEffect(() => {
        const isChangesMadeByOthers =
            prevCartItemModifierCategoryModifierItems !== undefined &&
            prevCartItemModifierCategoryModifierItems.length === cartItemModifierCategoryModifierItems.length &&
            prevCartItemModifierCategoryModifierItems.every(x => cartItemModifierCategoryModifierItems.some(y => x.menuModifierItemGuid === y.menuModifierItemGuid && x.quantity === y.quantity));

        if (isChangesMadeByOthers) return;

        // Update badge
        const currentValidationDtl = CustomizeBuilder.getValidationDtl(minQty, totalQty);
        if (validationDtl === null) {
            setValidationDtl(currentValidationDtl);
        } else if (validationDtl.badgeText !== currentValidationDtl.badgeText) {
            currentValidationDtl.badgeClass += ` ${withAnimationClass}`;
            setValidationDtl(currentValidationDtl);
        }

        if (!activeEventKey) return;

        // Collapse accordion if selected up to maxQty / all options are selected
        const isReachedMaxQty = maxQty && maxQty === totalQty;
        const selectedAllOptions = modifierCategory.items.every(x => x.isOutOfStock || cartItemModifierCategoryModifierItems.some(y => y.menuModifierCategoryGuid === menuModifierCategoryGuid && y.menuModifierItemGuid === x.menuModifierItemGuid));

        if (isReachedMaxQty || selectedAllOptions) {
            toggleAccordion(menuModifierCategoryGuid);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cartItemModifierItems]);

    function handleRadioInputChange(modifierItem) {
        const cartItemModifierItem = buildCartItemModifierItem(modifierItem);

        handleModifierItemChange({
            modifierItems: [
                ...cartItemModifierItems.filter(x => x.menuModifierCategoryGuid !== menuModifierCategoryGuid),
                { ...cartItemModifierItem }
            ]
        });
    }

    function handleCheckboxInputChange(checked, modifierItem) {
        if (checked) {
            if (maxQty && maxQty === totalQty && maxQty > 1) {
                Toast.showErrorMessage(`You are not allowed to select more than ${maxQty} for ${name}.`);
                return;
            }

            const cartItemModifierItem = buildCartItemModifierItem(modifierItem);

            if (maxQty && maxQty === 1) {
                handleModifierItemChange({
                    modifierItems: [
                        ...cartItemModifierItems.filter(x => x.menuModifierCategoryGuid !== menuModifierCategoryGuid),
                        { ...cartItemModifierItem }
                    ]
                });
            } else {
                handleModifierItemChange({
                    modifierItems: [
                        ...cartItemModifierItems,
                        { ...cartItemModifierItem }
                    ]
                });
            }
        } else {
            handleModifierItemChange({
                modifierItems: cartItemModifierItems.filter(x => x.menuModifierItemGuid !== modifierItem.menuModifierItemGuid)
            });
        }
    }

    function handleOpenImageDialog(e, image) {
        EventUtils.cancelEventPropagation(e);
        openImageDialog(image);
    }

    function buildCartItemModifierItem(modifierItem) {
        // Build CartItemModifierItem (CartItemModifierItemViewModel / CartSetMealItemModifierItemViewModel)
        return isRenderedByMenuItem
            ? cart.buildCartItemModifierItem(modifierCategory, modifierItem)
            : cart.buildCartSetMealItemModifierItem(cartSetMealItem, modifierCategory, modifierItem);
    }

    function renderModifierItem(modifierItem) {
        const value = modifierItem.menuModifierItemGuid;
        const groupValue = menuModifierCategoryGuid;

        const checked = cartItemModifierCategoryModifierItems.some(x => x.menuModifierItemGuid === modifierItem.menuModifierItemGuid);
        const disabled = modifierItem.isOutOfStock;

        const inputContent = isRadioInputType
            ? <Radio
                value={value}
                groupValue={groupValue}
                checked={checked}
                disabled={disabled}
                handleChange={() => handleRadioInputChange(modifierItem)}
            />
            : <Checkbox
                value={value}
                checked={checked}
                disabled={disabled}
                handleChange={checked => handleCheckboxInputChange(checked, modifierItem)}
            />;

        return (
            <RippleWrapper
                key={value}
                className="d-flex mt-3"
                style={{ opacity: disabled ? 0.5 : undefined }}
                htmlFor={value}
            >
                {modifierItem.image &&
                    <Image
                        className="me-3"
                        style={{ borderRadius: '6px' }}
                        width='65px'
                        height='65px'
                        src={modifierItem.image}
                        onClick={e => handleOpenImageDialog(e, modifierItem.image)}
                    />
                }

                <div className="flex-grow-1 d-flex flex-column fs-5">
                    <span>{modifierItem.description}</span>

                    <MenuItemPrice
                        as="span"
                        className="text-muted"
                        value={modifierItem.surcharge}
                        prefix="+"
                        prefixSmallFont
                        isCurrencyFormat
                    />

                    {disabled &&
                        <span className="fw-bold mb-1" style={{ color: 'var(--primary-dark)' }}>
                            Not Available
                        </span>
                    }
                </div>

                <div className="col-1 align-self-center text-center ms-3">
                    {inputContent}
                </div>
            </RippleWrapper>
        );
    }

    function renderSelectedModifierItem(modifierItem) {
        return (
            <div
                key={isRenderedByMenuItem ? modifierItem.cartItemModifierItemId : modifierItem.cartSetMealItemModifierItemId}
                className="d-flex text-muted fs-5 mt-2"
            >
                <span className="flex-grow-1">{modifierItem.description}</span>

                <MenuItemPrice
                    as="span"
                    className="col-3 ms-1 text-end"
                    value={modifierItem.surcharge}
                    prefix="+"
                    prefixSmallFont
                    isCurrencyFormat
                />
            </div>
        );
    }

    return (
        <div
            id={menuModifierCategoryGuid}
            className="apps-ui-menu-item-component-customize-panel d-flex flex-column p-3"
        >
            {/* Accordion Header */}
            <div className="d-flex flex-column" onClick={() => toggleAccordion(menuModifierCategoryGuid)}>
                <div className="d-flex align-items-baseline">
                    <span className="flex-grow-1 fw-bold">
                        {name}
                    </span>

                    {validationDtl
                        ? <span className={`col-3 text-white ms-auto ${validationDtl.badgeClass}`}>
                            {validationDtl.badgeText}
                        </span>
                        : <span className="col-3 ms-auto" />
                    }

                    <AccordionButton
                        primary
                        className="col-1 ms-1"
                        eventKey={menuModifierCategoryGuid.toString()}
                        activeEventKey={activeEventKey}
                        ref={el => accordionButtonRef.current = el}
                    />
                </div>

                {isAccordionExpanded
                    ? allowedQtyText && <span className="text-muted fs-6" style={{ fontWeight: 600 }}>{allowedQtyText}</span>
                    : cartItemModifierCategoryModifierItems.map(renderSelectedModifierItem)
                }
            </div>

            {/* Accordion Body */}
            <Accordion.Collapse eventKey={menuModifierCategoryGuid.toString()}>
                <>
                    {modifierCategory.items.map((x, index) => renderModifierItem(x, index === 0))}

                    {validationDtl && validationDtl.showValidator &&
                        <ValidatorElement message={`Please select at least ${minQty} item(s).`} />
                    }
                </>
            </Accordion.Collapse>
        </div>
    );
}

const hocs = [
    withOrderService
];

export default ComponentUtils.compose(hocs)(ModifierCategoryPanel);