import React from 'react';

const Icon = ({ name, label, showIconFirst = true }) => {
    return (
        showIconFirst
            ? <>
                <span className={`me-2 ${name}`} />
                <span>{label}</span>
            </>
            : <>
                <span className="me-2">{label}</span>
                <span className={name} />
            </>
    );
}

export default Icon;