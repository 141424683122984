import React from 'react';
import Checkbox from 'components/Checkbox';
import RippleWrapper from 'components/RippleWrapper';
import * as ServiceTypeConstants from 'data/serviceTypeConstant';

const TakeAwayPanel = ({ data, handleServiceTypeChange }) => {
    return (
        <RippleWrapper
            className="apps-ui-menu-item-component-customize-panel d-flex align-items-center p-3"
            htmlFor={data.menuItemGuid}
        >
            <span className="fw-bold">Take Away</span>

            <span
                className="ms-auto col-1 d-inline-flex align-items-center justify-content-center"
                style={{ paddingTop: '1px' }}
            >
                <Checkbox
                    value={data.menuItemGuid}
                    checked={data.serviceType === ServiceTypeConstants.TakeAway}
                    handleChange={handleServiceTypeChange}
                />
            </span>
        </RippleWrapper>
    );
}

export default TakeAwayPanel;