import React, { Component } from 'react';
import { getDisplayName } from 'utils/component';
import QuitOrderDialog from '../components/QuitOrderDialog';

export default function withQuitOrder(WrappedComponent) {
    class WithQuitOrder extends Component {
        constructor(props) {
            super(props);

            this.state = {
                showQuitOrderDialog: false
            };

            this.openQuitOrderDialog = this.openQuitOrderDialog.bind(this);
            this.closeQuitOrderDialog = this.closeQuitOrderDialog.bind(this);
        }

        openQuitOrderDialog() {
            this.setState({ showQuitOrderDialog: true });
        }

        closeQuitOrderDialog() {
            this.setState({ showQuitOrderDialog: false });
        }

        render() {
            const { showQuitOrderDialog } = this.state;

            return (
                <>
                    <WrappedComponent
                        openQuitOrderDialog={this.openQuitOrderDialog}
                        {...this.props}
                    />

                    {showQuitOrderDialog &&
                        <QuitOrderDialog
                            closeDialog={this.closeQuitOrderDialog}
                        />
                    }
                </>
            );
        }
    }

    WithQuitOrder.displayName = `WithQuitOrder(${getDisplayName(WrappedComponent)})`;

    return WithQuitOrder;
}