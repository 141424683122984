import React, { useState } from 'react';
import BottomSheetDialog from 'components/BottomSheetDialog';
import Button from 'components/Button';
import Image from 'components/Image';
import Radio from 'components/Radio';
import RippleWrapper from 'components/RippleWrapper';
import { getPaymentChannel, getPaymentChannelText } from 'data/paymentChannelText';
import withSettings from 'hocs/withSettings';
import * as ComponentUtils from 'utils/component';

const PaymentChannelDialog = ({ closeDialog, handleSubmit, settings }) => {
    const { allowPayLaterAtCounter, restaurantPaymentChannels } = settings;

    const [paymentChannelData, setPaymentChannelData] = useState({
        paymentGatewayChannelId: null,
        paymentChannelId: null,
        payLaterAtCounter: false
    });

    const isPaymentChannelSelected = paymentChannelData.paymentGatewayChannelId !== null || paymentChannelData.payLaterAtCounter;

    const items = buildPaymentChannelItems();

    function buildPaymentChannelItems() {
        // Add enabled PaymentChannels
        const results = restaurantPaymentChannels.reduce((arr, x) => {
            const paymentChannelItem = getPaymentChannel(x.paymentChannelId);

            if (paymentChannelItem !== null) {
                arr.push({
                    ...x,
                    key: x.paymentChannelId,
                    text: paymentChannelItem.text,
                    imageSrc: paymentChannelItem.imageSrc,
                    isPayLaterAtCounter: false
                });
            }

            return arr;
        }, []);

        // Add PayLaterAtCounter PaymentChannel if allowed
        if (allowPayLaterAtCounter) {
            results.push({
                key: Math.max(...results.map(x => x.paymentChannelId)) + 1,
                text: 'Pay Later At Counter',
                imageSrc: '/img/payment-channel-pay-later-at-counter-logo.png',
                isPayLaterAtCounter: true
            });
        }

        // Sort results
        results.sort((a, b) => a.paymentChannelId - b.paymentChannelId);

        return results;
    }

    function buildSubmitButtonText() {
        let result = '';

        if (isPaymentChannelSelected) {
            if (paymentChannelData.paymentGatewayChannelId !== null) {
                result = `Pay using ${getPaymentChannelText(paymentChannelData.paymentChannelId)}`;
            } else {
                result = 'Pay Later At Counter';
            }
        } else {
            result = 'No Payment Channel Selected';
        }

        return result;
    }

    function handlePaymentChannelDataChanged(item) {
        setPaymentChannelData({
            paymentGatewayChannelId: item.paymentGatewayChannelId ? item.paymentGatewayChannelId : null,
            paymentChannelId: item.paymentChannelId ? item.paymentChannelId : null,
            payLaterAtCounter: item.isPayLaterAtCounter
        });
    }

    return (
        <BottomSheetDialog
            title="Select Payment Channel"
            isOpen
            closeDialog={closeDialog}
            footer={
                <div
                    className="apps-general-box-shadow d-flex justify-content-center py-3"
                    style={{ borderTop: '1px solid var(--grey-300)' }}
                >
                    <Button
                        className="col-8 py-2"
                        primary
                        disabled={!isPaymentChannelSelected}
                        onClick={() => handleSubmit(paymentChannelData)}
                    >
                        {buildSubmitButtonText()}
                    </Button>
                </div>
            }
        >
            {items.length > 0
                ? <div className="d-flex flex-column">
                    {items.map(x =>
                        <RippleWrapper
                            key={x.key}
                            className="d-flex align-items-center p-3"
                            htmlFor={x.key}
                        >
                            <Image
                                className="border p-1"
                                style={{ objectFit: 'contain', borderRadius: '6px' }}
                                width="65px"
                                height="65px"
                                src={x.imageSrc}
                            />

                            <span className="flex-grow-1 fs-5 ms-3" style={{ fontWeight: 600 }}>
                                {x.text}
                            </span>

                            <span className="col-1 d-flex align-items-center align-self-center">
                                <Radio
                                    value={x.key}
                                    groupValue="payment-channel-list"
                                    checked={x.isPayLaterAtCounter
                                        ? paymentChannelData.payLaterAtCounter
                                        : paymentChannelData.paymentGatewayChannelId === x.paymentGatewayChannelId}
                                    handleChange={() => handlePaymentChannelDataChanged(x)}
                                />
                            </span>
                        </RippleWrapper>
                    )}
                </div>
                : <div className="d-flex flex-column align-items-center p-4">
                    <Image
                        src="/img/empty-payment-channel-logo.png"
                        style={{ objectFit: 'contain' }}
                        width="150px"
                        height="150px"
                    >
                    </Image>

                    <span className="text-center">Sorry, no payment channels are available at the moment.</span>
                </div>
            }
        </BottomSheetDialog>
    );
}

const hocs = [
    withSettings
];

export default ComponentUtils.compose(hocs)(PaymentChannelDialog);