import React, { Component } from 'react';
import Badge from 'components/Badge';
import EmptyLine from 'components/EmptyLine';
import HintText from 'components/HintText';
import Image from 'components/Image';
import Overlay from 'components/Overlay';
import * as DocumentIdConstants from 'data/documentIdConstant';
import { getMenuItemTag } from 'data/menuItemTagText';
import { getServiceTypeText } from 'data/serviceTypeText';
import withBlockUI from 'hocs/withBlockUI';
import withMenu from 'hocs/withMenu';
import withOrderService from 'hocs/withOrderService';
import withRouter from 'hocs/withRouter';
import withUser from 'hocs/withUser';
import MenuItemImage from 'Menu/components/MenuItemImage';
import MenuItemName from 'Menu/components/MenuItemName';
import MenuItemPrice from 'Menu/components/MenuItemPrice';
import MenuItemQuantityIcon from 'Menu/components/MenuItemQuantityIcon';
import withCartModeFunction from 'ui/CartMode/hocs/withCartMode';
import withMenuItemFunction from 'ui/MenuItem/hocs/withMenuItem';
import withOrderFunction from 'ui/Order/hocs/withOrder';
import * as ComponentUtils from 'utils/component';
import * as EventUtils from 'utils/eventUtils';
import * as Toast from 'utils/toast';
import Footer from './Footer';
import MenuCategoryListingDialog from './MenuCategoryListingDialog';
import MenuItemNotFound from './MenuItemNotFound';
import SearchBar from './SearchBar';
import Sidenav from './Sidenav';

const activeNavClass = 'is-active';

class Page extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchValue: null,
            isSearchBarOpened: false,
            showMenuCategoryListingDialog: false,
            showSidenav: false
        };

        this.appBar = React.createRef();
        this.appBarViewName = React.createRef();
        this.backgroundImage = React.createRef();
        this.scrollSpyNav = React.createRef();
        this.floatingActionButton = React.createRef();

        this.menuCategories = [];
        this.activeMenuCategoryGuid = null;

        this.openMenuCategoryListingDialog = this.openMenuCategoryListingDialog.bind(this);
        this.closeMenuCategoryListingDialog = this.closeMenuCategoryListingDialog.bind(this);
        this.openSidenav = this.openSidenav.bind(this);
        this.closeSidenav = this.closeSidenav.bind(this);
        this.openSearchBar = this.openSearchBar.bind(this);
        this.closeSearchBar = this.closeSearchBar.bind(this);
        this.onSearchValueChanged = this.onSearchValueChanged.bind(this);
        this.handleAppBarViewNameScrolling = this.handleAppBarViewNameScrolling.bind(this);
        this.toggleClickListenerForNavLinks = this.toggleClickListenerForNavLinks.bind(this);
        this.activateFirstNavLink = this.activateFirstNavLink.bind(this);
        this.onNavLinkClick = this.onNavLinkClick.bind(this);
        this.onScroll = this.onScroll.bind(this);
        this.deactivateNavLinks = this.deactivateNavLinks.bind(this);
        this.activateNavLink = this.activateNavLink.bind(this);
        this.scrollNavLinkIntoView = this.scrollNavLinkIntoView.bind(this);
        this.doNavScroll = this.doNavScroll.bind(this);
        this.setActiveMenuCategoryGuid = this.setActiveMenuCategoryGuid.bind(this);
        this.viewCart = this.viewCart.bind(this);
        this.viewOrder = this.viewOrder.bind(this);
        this.viewMenuItem = this.viewMenuItem.bind(this);
    }

    componentDidMount() {
        // Add scroll listener
        window.addEventListener('scroll', this.onScroll);

        // Add click listener to nav links
        this.toggleClickListenerForNavLinks(true);

        // Set first nav link as active
        this.activateFirstNavLink();

        // Add animation to app bar view name if overflow
        this.handleAppBarViewNameScrolling();
    }

    componentDidUpdate(_, prevState) {
        const isClosingSearchBar = !this.state.isSearchBarOpened && prevState.isSearchBarOpened;
        const isSearchValueChanged = this.state.searchValue !== prevState.searchValue;

        if (isClosingSearchBar || isSearchValueChanged) {
            this.toggleClickListenerForNavLinks(true);
            this.activateFirstNavLink();

            if (isClosingSearchBar) {
                this.handleAppBarViewNameScrolling();
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll);
        this.toggleClickListenerForNavLinks(false);
    }

    getNavLinkElement(menuCategoryGuid) {
        return document.querySelector(`a[href="#${menuCategoryGuid}"]`);
    }

    getMenuCategoryContentElement(menuCategoryGuid) {
        return document.getElementById(menuCategoryGuid);
    }

    getMenuItemsToRender(menuCategory) {
        const { searchValue } = this.state;
        let menuItems = menuCategory.items;

        const includesIgnoreCase = src => src.toLowerCase().includes(searchValue.toLowerCase());
        if (searchValue) {
            menuItems = menuItems.filter(x =>
                includesIgnoreCase(x.name) ||
                (x.displayCode !== null && includesIgnoreCase(x.displayCode)));
        }

        return menuItems;
    }

    getTotalSelectedQty(menuCategory) {
        return this.props.cart.items
            .filter(x => menuCategory.items.some(y => y.menuItemGuid === x.menuItemGuid))
            .reduce((total, item) => total + item.quantity, 0);
    }

    openMenuCategoryListingDialog() {
        this.setState({ showMenuCategoryListingDialog: true });
    }

    closeMenuCategoryListingDialog() {
        this.setState({ showMenuCategoryListingDialog: false });
    }

    openSidenav() {
        this.setState({ showSidenav: true });
    }

    closeSidenav() {
        this.setState({ showSidenav: false });
    }

    openSearchBar() {
        this.setState({ isSearchBarOpened: true });
    }

    closeSearchBar() {
        this.onSearchValueChanged(null);
        this.setState({ isSearchBarOpened: false });
    }

    onSearchValueChanged(value) {
        this.setState({ searchValue: value });
    }

    handleAppBarViewNameScrolling() {
        const appBarViewNameEl = this.appBarViewName.current;

        if (appBarViewNameEl.scrollWidth > appBarViewNameEl.offsetWidth) {
            appBarViewNameEl.classList.add('show-scrolling-text');
        }
    }

    toggleClickListenerForNavLinks(isAdd) {
        const scrollSpyNavEl = this.scrollSpyNav.current;
        if (!scrollSpyNavEl) return;

        scrollSpyNavEl.querySelectorAll('a').forEach(x => {
            if (isAdd) {
                x.addEventListener('click', this.onNavLinkClick);
            } else {
                x.removeEventListener('click', this.onNavLinkClick);
            }
        });
    }

    activateFirstNavLink() {
        // Activate first nav link
        const menuCategoryGuids = this.menuCategories.map(x => x.menuCategoryGuid);
        if (menuCategoryGuids.length === 0) return;

        const firstMenuCategoryGuid = menuCategoryGuids[0];

        this.deactivateNavLinks();
        this.activateNavLink(firstMenuCategoryGuid);
        this.setActiveMenuCategoryGuid(firstMenuCategoryGuid);
    }

    onNavLinkClick(e) {
        EventUtils.preventDefault(e);

        const navHref = e.target.getAttribute('href');
        const menuCategoryGuid = navHref.includes('#') ? navHref.replace('#', '') : '';
        this.doNavScroll(menuCategoryGuid);
    }

    onScroll() {
        const menuCategoryGuids = this.menuCategories.map(x => x.menuCategoryGuid);
        const appBarEl = this.appBar.current;
        const backgroundImageEl = this.backgroundImage.current;
        const scrollSpyNavEl = this.scrollSpyNav.current;
        const floatingActionButtonEl = this.floatingActionButton.current;

        // Show AppBar once window is scrolled after BackgroundImage
        if (window.scrollY <= backgroundImageEl.scrollHeight - appBarEl.scrollHeight) {
            appBarEl.classList.add('text-white');
            appBarEl.classList.remove('bg-white');

            if (scrollSpyNavEl) {
                scrollSpyNavEl.classList.remove('position-sticky');
                scrollSpyNavEl.style.removeProperty('top');
                scrollSpyNavEl.style.removeProperty('z-index');
            }
        } else {
            appBarEl.classList.add('bg-white');
            appBarEl.classList.remove('text-white');

            if (scrollSpyNavEl) {
                scrollSpyNavEl.classList.add('position-sticky');
                scrollSpyNavEl.style.top = '50px';
                scrollSpyNavEl.style.zIndex = 1;
            }
        }

        if (!scrollSpyNavEl) return;

        // Set current in view nav link as active
        const appBarHeight = appBarEl.scrollHeight;
        const scrollSpyNavHeight = scrollSpyNavEl.scrollHeight;

        for (const menuCategoryGuid of menuCategoryGuids) {
            const menuCategoryContentEl = this.getMenuCategoryContentElement(menuCategoryGuid);
            if (!menuCategoryContentEl) return;

            // Note : Effective offset top does not consider sticky / fixed elements 
            const effectiveOffsetTop = menuCategoryContentEl.offsetTop - appBarHeight - scrollSpyNavHeight;
            const isScrolledAfterMenuCategory = window.scrollY > effectiveOffsetTop + menuCategoryContentEl.scrollHeight;

            if (!isScrolledAfterMenuCategory) {
                if (this.activeMenuCategoryGuid !== menuCategoryGuid) {
                    this.deactivateNavLinks();
                    this.activateNavLink(menuCategoryGuid);
                    this.scrollNavLinkIntoView(menuCategoryGuid);
                    this.setActiveMenuCategoryGuid(menuCategoryGuid);
                }
                break;
            }
        }

        // Show FloatingActionButton once window is scrolled down
        if (window.scrollY > 0) {
            floatingActionButtonEl.style.visibility = 'visible';
        } else {
            floatingActionButtonEl.style.visibility = 'hidden';
        }
    }

    deactivateNavLinks() {
        const menuCategoryGuids = this.menuCategories.map(x => x.menuCategoryGuid);

        for (const menuCategoryGuid of menuCategoryGuids) {
            const navLinkEl = this.getNavLinkElement(menuCategoryGuid);
            navLinkEl.parentElement.classList.remove(activeNavClass);
        }
    }

    activateNavLink(menuCategoryGuid) {
        const navLinkEl = this.getNavLinkElement(menuCategoryGuid);
        navLinkEl.parentElement.classList.add(activeNavClass);
    }

    scrollNavLinkIntoView(menuCategoryGuid) {
        // Scroll nav link into view horizontally
        const navLinkEl = this.getNavLinkElement(menuCategoryGuid);

        const navTabEl = document.getElementById(DocumentIdConstants.MenuPageNavTab);
        const navTabRect = navTabEl.getBoundingClientRect();
        const navLinkRect = navLinkEl.getBoundingClientRect();
        const navLinkCenter = navLinkRect.left + navLinkRect.width / 2;

        const scrollLeft = navTabEl.scrollLeft + navLinkCenter - navTabRect.left - navTabRect.width / 2;
        navTabEl.scrollTo({ left: scrollLeft, behavior: 'smooth' });
    }

    doNavScroll(menuCategoryGuid) {
        const menuCategoryContentEl = this.getMenuCategoryContentElement(menuCategoryGuid);
        if (!menuCategoryContentEl) return;

        const scrollSpyNavHeight = this.scrollSpyNav.current.scrollHeight;
        const appBarHeight = this.appBar.current.scrollHeight;
        const effectiveOffsetTop = menuCategoryContentEl.offsetTop - appBarHeight - scrollSpyNavHeight;

        window.scrollTo({ top: effectiveOffsetTop });
    }

    setActiveMenuCategoryGuid(menuCategoryGuid) {
        this.activeMenuCategoryGuid = menuCategoryGuid;
    }

    // Trigger HOCs' functions
    viewCart() {
        this.props.viewCart();
    }

    viewOrder() {
        this.props.viewOrder();
    }

    viewMenuItem(menuItemGuid) {
        this.props.addItem(menuItemGuid)
            .then(e => {
                if (e.actionMessage) Toast.showSuccessMessage(e.actionMessage);
            })
            .catch(errorMessage => {
                Toast.showErrorMessage(errorMessage);
            });
    }

    // Render functions
    renderMenuCategoryContent(menuCategory, isFirstMenuCategory) {
        const { isTrending } = menuCategory;
        const menuItems = this.getMenuItemsToRender(menuCategory);

        return (
            <div
                id={menuCategory.menuCategoryGuid}
                key={menuCategory.menuCategoryGuid}
                className={`d-flex flex-column bg-white px-3 ${isFirstMenuCategory ? 'pt-3' : 'pt-4'}`}
                style={{ borderBottom: '8px solid var(--grey-100)' }}
            >
                <div className="d-flex align-items-center">
                    {isTrending &&
                        <span className="fas fa-fire me-2" style={{ color: '#e04006' }} />
                    }

                    <span className="fs-2 fw-bold">{menuCategory.name}</span>
                </div>

                <HintText>{menuCategory.description}</HintText>

                {isTrending
                    ? <div className="d-flex flex-wrap mb-3">
                        {menuItems.filter(x => !x.isOutOfStock).map((x, index) => this.renderTrendingMenuItemContent(x, index % 2 === 0))}
                    </div>
                    : menuItems.map((x, index) => this.renderMenuItemContent(x, menuItems.length - 1 === index))
                }
            </div>
        );
    }

    renderMenuItemContent(menuItem, isLastMenuItem) {
        const { cart } = this.props;
        const { isOutOfStock, isTrending } = menuItem;
        const qty = cart.calculateMenuItemTotalQuantity(menuItem.menuItemGuid);

        return (
            <React.Fragment key={menuItem.menuItemGuid}>
                <div
                    className="d-flex py-3"
                    style={{ opacity: isOutOfStock ? 0.5 : undefined }}
                    onClick={() => !isOutOfStock && this.viewMenuItem(menuItem.menuItemGuid)}
                >
                    <div className="position-relative">
                        <MenuItemImage
                            className="border"
                            style={{ borderRadius: '6px' }}
                            imageSrc={menuItem.image}
                            name={menuItem.name}
                            width='80px'
                            height='80px'
                            isLazyLoading
                        />

                        {qty > 0 &&
                            <MenuItemQuantityIcon
                                className="position-absolute"
                                style={{ top: '-10px', left: '-5px' }}
                                border
                                shadow
                            >
                                {qty}
                            </MenuItemQuantityIcon>
                        }
                    </div>

                    <div className="d-flex flex-column flex-grow-1 ps-3">
                        {(isTrending || isOutOfStock) &&
                            <span className="d-flex align-items-center fs-6 mb-2">
                                {isTrending && <span className="text-primary me-3">Most Ordered</span>}
                                {isOutOfStock && <span className="text-danger">Unavailable</span>}
                            </span>
                        }

                        <span className="fs-5">
                            <MenuItemName name={menuItem.name} displayCode={menuItem.displayCode} />
                        </span>

                        <span className="text-muted fs-6 mt-1">{menuItem.description}</span>

                        {this.renderPriceAndTagsForMenuItem(menuItem)}
                    </div>
                </div>

                {!isLastMenuItem && <EmptyLine />}
            </React.Fragment>
        );
    }

    renderTrendingMenuItemContent(menuItem, isLeftItem) {
        const { cart } = this.props;
        const qty = cart.calculateMenuItemTotalQuantity(menuItem.menuItemGuid);

        return (
            <div
                key={menuItem.menuItemGuid}
                className={`d-flex flex-column pt-3 ${isLeftItem ? '' : 'ms-auto'}`}
                style={{ flexBasis: '45%' }}
                onClick={() => this.viewMenuItem(menuItem.menuItemGuid)}
            >
                <div className="position-relative w-100" style={{ height: 0, paddingBottom: '100%' }}>
                    <MenuItemImage
                        className="position-absolute w-100 h-100"
                        style={{
                            borderRadius: '5px',
                            top: 0,
                            left: 0
                        }}
                        imageSrc={menuItem.image}
                        name={menuItem.name}
                        isLazyLoading
                    />

                    {qty > 0 &&
                        <MenuItemQuantityIcon
                            className="position-absolute"
                            style={{ top: '-10px', left: '-5px' }}
                            minWidth='30px'
                            height='30px'
                            border
                            shadow
                        >
                            {qty}
                        </MenuItemQuantityIcon>
                    }
                </div>

                <span className="fs-5 mt-1">
                    <MenuItemName name={menuItem.name} displayCode={menuItem.displayCode} />
                </span>

                {this.renderPriceAndTagsForMenuItem(menuItem)}
            </div>
        );
    }

    renderPriceAndTagsForMenuItem(menuItem) {
        const price = (menuItem.setMeal ? menuItem.setMeal.priceFrom : menuItem.price) || 0;
        const isSetMeal = menuItem.setMeal;

        return (
            <div className="d-flex align-items-center flex-wrap gap-2 fs-5 mt-2">
                <MenuItemPrice
                    as="span"
                    style={{ fontWeight: 500 }}
                    value={price}
                    suffix={`${isSetMeal ? '++' : ''}`}
                    prefixSmallFont
                    suffixSmallFont
                    isCurrencyFormat={false}
                />

                {menuItem.tags &&
                    menuItem.tags.split(', ').map((tag, index) => {
                        const menuItemTag = getMenuItemTag(tag);
                        const menuItemTagText = menuItemTag ? menuItemTag.text : '';
                        const menuItemTagColor = menuItemTag ? menuItemTag.color : '';

                        return (
                            <Overlay
                                key={index}
                                trigger="click"
                                placement="top"
                                autoCloseTime={1000}
                                popoverText={tag}
                            >
                                <span
                                    className={`${menuItemTagText} p-1`}
                                    style={{
                                        color: menuItemTagColor,
                                        background: 'rgba(239, 239, 239, 0.4)',
                                        border: '1px solid #e6e6e6',
                                        borderRadius: '6px'
                                    }}
                                    onClick={EventUtils.cancelEventPropagation}
                                />
                            </Overlay>
                        );
                    })
                }
            </div>
        );
    }

    render() {
        const { menu, settings, orderServiceType, tableNo } = this.props;
        const { searchValue, isSearchBarOpened, showMenuCategoryListingDialog, showSidenav } = this.state;
        const { categories } = menu;
        const { description, viewName, viewLogo, viewMenuPageBackgroundImage } = settings;

        const backgroundImage = viewMenuPageBackgroundImage
            ? `url(${viewMenuPageBackgroundImage})`
            : 'url("/img/menu-default-header-background.png")';

        this.menuCategories = categories
            .filter(x => this.getMenuItemsToRender(x).length > 0)
            .map(x => ({
                ...x,
                totalSelectedQty: this.getTotalSelectedQty(x)
            }));

        const menuCategoryContents = this.menuCategories.map((x, index) => this.renderMenuCategoryContent(x, index === 0));

        return (
            <>
                <ViewOrderFromUrl location={this.props.location} viewOrder={this.viewOrder} />

                {/* AppBar */}
                <div
                    className="apps-page-menu-app-bar d-flex align-items-center text-white position-fixed w-100"
                    ref={this.appBar}
                >
                    {isSearchBarOpened
                        ? <>
                            <SearchBar onSearch={this.onSearchValueChanged} />
                            <div className="col-1 fas fa-times fs-1 ms-auto pe-3" onClick={this.closeSearchBar} />
                        </>
                        : <>
                            <div className="col-1 fas fa-bars fs-1 ps-3" onClick={this.openSidenav} />

                            <div
                                className="view-name flex-grow-1 fs-3 fw-bold mx-3"
                                style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}
                                ref={this.appBarViewName}
                            >
                                <div>{viewName}</div>
                            </div>

                            {this.menuCategories.length > 0 &&
                                <div className="col-1 fas fa-search fs-1 ms-auto pe-3" onClick={this.openSearchBar} />
                            }
                        </>
                    }
                </div>

                {/* BackgroundImage */}
                <div
                    style={{
                        height: '300px',
                        backgroundImage: backgroundImage,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        filter: 'brightness(0.7)'
                    }}
                    ref={this.backgroundImage}
                />

                {/* Restaurant Info */}
                <div className="d-flex p-3">
                    <div className="flex-grow-1 d-flex flex-column">
                        <span className="fs-3 fw-bold">{viewName}</span>

                        {description &&
                            <HintText className="mt-1">{description}</HintText>
                        }

                        <div className="d-flex flex-wrap gap-2 fs-6 mt-3">
                            <Badge>{`Order Type: ${getServiceTypeText(orderServiceType)}`}</Badge>
                            {tableNo && <Badge>{`Table No: ${tableNo}`}</Badge>}
                        </div>
                    </div>

                    {viewLogo &&
                        <Image
                            className="col-3"
                            style={{ objectFit: 'cover' }}
                            src={viewLogo}
                        />
                    }
                </div>

                {this.menuCategories.length > 0
                    ? <>
                        {/* ScrollspyNav */}
                        <div
                            className="apps-general-box-shadow apps-page-menu-scrollspy d-flex flex-column bg-white w-100"
                            style={{ borderBottom: '1px solid var(--grey-300)' }}
                            ref={this.scrollSpyNav}
                        >
                            <div className="d-flex align-items-center">
                                <ul id={DocumentIdConstants.MenuPageNavTab} className="d-flex mb-0 ps-0">
                                    {this.menuCategories.map(x =>
                                        <li key={x.menuCategoryGuid} className="px-3 py-2">
                                            <a href={`#${x.menuCategoryGuid}`}>{x.name}</a>
                                        </li>
                                    )}
                                </ul>

                                <div className="col-1 fas fa-chevron-down fs-1 ms-auto pe-3" onClick={this.openMenuCategoryListingDialog} />
                            </div>
                        </div>

                        {/* Menu Details */}
                        <div>
                            {menuCategoryContents}
                            <div style={{ height: '10vh' }}></div>
                        </div>
                    </>
                    : <MenuItemNotFound>
                        {`${searchValue
                            ? `We couldn't find Item "${searchValue}" in our menu.`
                            : `We don't have any available items to sell for now.`}`
                        }
                    </MenuItemNotFound>
                }

                {/* Floating Action Button */}
                <div
                    className="position-fixed"
                    style={{
                        left: 0,
                        right: 0,
                        bottom: 0,
                        margin: 'auto',
                        maxWidth: 'var(--max-width)',
                        height: 0,
                        visibility: window.scrollY > 0 ? 'visible' : 'hidden'
                    }}
                    ref={this.floatingActionButton}
                >
                    <span
                        className="fas fa-angle-double-up position-absolute me-3"
                        style={{
                            right: 0,
                            bottom: '80px',
                            fontSize: '25px',
                            cursor: 'pointer'
                        }}
                        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                    />
                </div>

                {/* Footer */}
                {categories.length > 0 &&
                    <Footer viewCart={this.viewCart} />
                }

                {showMenuCategoryListingDialog &&
                    <MenuCategoryListingDialog
                        menuCategories={this.menuCategories}
                        activeMenuCategoryGuid={this.activeMenuCategoryGuid}
                        doNavScroll={this.doNavScroll}
                        closeDialog={this.closeMenuCategoryListingDialog}
                    />
                }

                {showSidenav &&
                    <Sidenav closeSidenav={this.closeSidenav} />
                }
            </>
        );
    }
}

const hocs = [
    withBlockUI,
    withMenu,
    withUser,
    withCartModeFunction,
    withOrderService,
    withRouter,
    withMenuItemFunction,
    withOrderFunction
];

export default ComponentUtils.compose(hocs)(Page);

const PaymentStatus = {
    Success: 'success',
    Failure: 'failure',
    InProgress: 'inprogress',
    Cancelled: 'cancelled'
};

class ViewOrderFromUrl extends Component {
    componentDidMount() {
        const { location, viewOrder } = this.props;

        const urlParams = new URLSearchParams(location.search);
        const urlPaymentStatus = urlParams.get('paymentStatus');

        if (urlPaymentStatus) {
            viewOrder();

            if (urlPaymentStatus === PaymentStatus.Success) {
                Toast.showSuccessMessage('Payment completed successfully.');
            } else if (urlPaymentStatus === PaymentStatus.Failure) {
                Toast.showErrorMessage('Payment failed.');
            } else if (urlPaymentStatus === PaymentStatus.InProgress) {
                Toast.showWarningMessage('Payment pending for approval.');
            } else if (urlPaymentStatus === PaymentStatus.Cancelled) {
                Toast.showWarningMessage('Payment cancelled.')
            }
        }
    }

    render() {
        return <></>;
    }
}