import React from 'react';
import * as EventUtils from 'utils/eventUtils';

const Checkbox = ({ value, checked, disabled, handleChange, allowPropogation = true }) =>
    <input
        id={value}
        value={value}
        className="form-check-input"
        type="checkbox"
        checked={checked}
        disabled={disabled}
        onChange={e => handleChange(e.target.checked)}
        onClick={e => !allowPropogation && EventUtils.stopPropagation(e)}
    />;

export default Checkbox;